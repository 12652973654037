.modal-item-filters__head {
  width: 100%;
  border-radius: 0;
}
.modal-item-filters__body {
  height: 100%;
  border-radius: 0;
}
.modal-item-filters__footer {
  height: 100%;
  border-radius: 0;
}

.modal-item-filters__footer .ant-card-body {
  padding: 2rem;
}
.modal-item-filters__footer .ant-card-body {
  padding: 2rem 1rem;
}

.modal-item-filters__tags {
  position: absolute;
  bottom: calc(100% + 2px);
  left: 0;
  right: 0;
  padding: 0.5rem 1rem 0.5rem;
  border-top: 2px solid #cbd5e0;
  background: #fff;
}
.modal-item-filters .ant-space-item {
  word-break: break-word;
}