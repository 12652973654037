.parent__childSettings__card {
	width: 50%;
	.ant-card-body {
		padding: 0px;
	}

	.ant-collapse-header,
	.ant-collapse-content,
	.ant-collapse-content-box {
		padding: 0px !important;
	}

	.ant-collapse-arrow {
		right: 0px !important;
	}
}

@media only screen and (max-width: @large) {
	.parent__childSettings__card {
		width: 60%;
	}
}

@media only screen and (max-width: @medium) {
	.parent__childSettings__card {
		width: 100%;
	}
}
