.courseBuilder__assignment {
	&.assignment-item {
		display: flex;
		flex-direction: row;
		align-items: flex-start;

		border: 1px solid @gray-5;
		box-sizing: border-box;
		border-radius: 6px;
	}
}
