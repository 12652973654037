.login1__logo {
  max-width: 260px;
  width: 100%;
  height: auto;
}

//@media only screen and max-width: @medium {
//  .login1__logo {
//    width: 100%;
//    height: auto;
//  }
//}

.login1__form {
  background: var(--background);
  color: var(--text-color);
  height: 100vh;


  label, span, a, .text {
    color: var(--text-color) !important;
  }
}

.big-layout__content {
  background-color: var(--background);
}