.assignment__grading_student_list {
	.ant-list-header {
		padding: 0;
		.grading__header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 8px 24px 8px 24px;
			background-color: @gray-3;
			.grade__label_wrapper {
				width: 168px;
			}
		}
	}
	.student__assignment_list_item_wrapper {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.student__assignment_collapse_wrapper {
		border: none;
		.student__assignment_collapse_panel_wrapper {
			border: none;
			.student__grade_wrapper {
				width: 100%;
				.student__grade_value_wrapper {
					display: flex;
					width: 100%;
				}
				@media only screen and (max-width: @small) {
					align-items: flex-start;
					.ant-avatar {
						width: 24px;
						height: 24px;
						.ant-avatar-string {
							line-height: 22px;
						}
					}
					.student__grade_value_wrapper {
						flex-direction: column;
					}
				}
				@media only screen and (min-width: @small) and (max-width: @medium) {
					align-items: center;
					.ant-space-item:nth-child(2) {
						width: 100%;
					}
					.student__grade_value_wrapper {
						flex-direction: row;
						justify-content: space-between;
					}
				}
			}
			.collapse__item_grade_input {
				width: 180px;
			}
			@media only screen and (min-width: @small) and (max-width: @medium) {
				.collapse__item_grade_input {
					width: 420px;
				}
			}
			.collapse__item_grade_button {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
			.ant-collapse-content {
				background-color: @gray-2;
			}
			.ant-upload-list-item {
				background-color: @gray-1;
			}
		}
	}

	@media only screen and (max-width: @medium) {
		.student__assignment_list_item_wrapper {
			display: none;
		}
	}
}
.assignment__grading_card_wrapper {
	display: flex;
	justify-content: center;
	.assignment__grading_card {
		.grading__card_title_wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.ant-card-body {
			padding: 0;
			.card__student_list_column {
				border-right: 1px solid @gray-5;
			}
		}
		.indicator__overview_icon {
			font-size: 16px;
		}
		.grading__indicator_divider {
			height: 44px;
			margin: 0 16px 0 16px;
		}
		.student__list_title_wrapper {
			padding: 16px;
			display: flex;
			align-items: center;
			.student__list_title {
				font-size: 16px;
				line-height: 24px;
				font-weight: 400;
			}
		}
	}

	@media only screen and (min-width: @x-small) {
		.assignment__grading_card {
			width: 289px;
			.ant-card-body {
				.card__student_list_column {
					border-right: none;
				}
			}
		}
	}

	@media only screen and (min-width: @small) {
		.assignment__grading_card {
			width: 528px;
			.ant-card-body {
				.card__student_list_column {
					border-right: none;
				}
			}
			.indicator__overview_icon {
				font-size: 24px;
			}
			.student__list_title_wrapper {
				padding: 24px 24px 16px 24px;
				.student__list_title {
					font-size: 20px;
					line-height: 28px;
					font-weight: 400;
				}
			}
		}
	}

	@media only screen and (min-width: @medium) {
		.assignment__grading_card {
			width: 720px;
			.ant-card-body {
				.card__student_list_column {
					border-right: 1px solid @gray-5;
					max-height: 662px;
					overflow-y: auto;
				}
			}
			.grading__card_title_wrapper {
				justify-content: space-between;
			}
		}
	}

	@media only screen and (min-width: @large) {
		.assignment__grading_card {
			width: 944px;
			.ant-card-body {
				.card__student_list_column {
					max-height: 680px;
					overflow-y: auto;
				}
			}
		}
	}

	@media only screen and (min-width: @x-large) {
		.assignment__grading_card {
			width: 904px;
			.ant-card-body {
				.card__student_list_column {
					max-height: 700px;
					overflow-y: auto;
				}
			}
		}
	}

	@media only screen and (min-width: @xx-large) {
		.assignment__grading_card {
			width: 904px;
			.ant-card-body {
				.card__student_list_column {
					max-height: 700px;
					overflow-y: auto;
				}
			}
		}
	}
}
