.classroom__thumbnail {
	width: 100%;
	position: relative;
	padding-bottom: 56.25%; /* ratio 16/9 */
	.classroom__thumbnail_image {
		border-radius: 6px;
		position: absolute;
		width: 100%;
		height: 100%;
		justify-content: center;
		cursor: pointer;
	}
	.classroom__thumbnail_icon {
		position: absolute;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, #062f96 0%, #2063e3 100%);
		border-radius: 6px;
		display: flex;
		justify-content: center;
		cursor: pointer;
		i {
			color: @blue-1;
			font-size: 40px;
		}
	}
}
