.main__classSlider__card {
	width: 97%;
	margin-top: 25px;
	overflow-x: hidden;
	.ant-card-body {
		padding-right: 0px;
		padding-bottom: 0px;
	}
	.archived__avatar {
		position: absolute;
		right: 24px;
		top: 8px;
		background-color: @gray-3;
	}
}

.main__classSlider__card__show__some {
	.ant-row {
		flex-flow: nowrap;
	}
}

.main__classSlider__card__show__all {
	.ant-row {
		flex-flow: wrap;
	}
}

.main__classSlider__nav__icon {
	position: absolute;
	z-index: 1;
	border: 1px solid @gray-4;
	border-radius: 71px;
	background-color: @gray-1;
	color: @gray-8;
	height: 30px;
	width: 30px;
	padding-left: 7px;
	padding-top: 3px;
}

.main__classSlider__nav__icon__right {
	right: 10px;
}

.main__classSlider__nav__icon__left {
	left: 10px;
}

.main__classSlider__item__card__image {
	width: 100%;
	height: auto;
	border-radius: 6px;
	background-color: @blue-6;
	height: 100%; //for safari
}

@media only screen and (max-width: @small) {
	.main__classSlider__card {
		width: 100%;
	}
}
