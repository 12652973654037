.dashboard__student_assignments {
	border: none;
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	.ant-card-body {
		padding: 24px 0 12px 0;
		.course__assignments_details {
			width: 100%;
			padding: 0;
			display: flex;
			.course__overview_content {
				width: calc(100% - 56px);
				.course__overview {
					padding: 24px;
					display: flex;
					align-items: center;
					.subject__icon {
						width: 32px;
						height: 32px;
						border-radius: 50%;
						background-color: @blue-1;
						display: flex;
						align-items: center;
						justify-content: center;
						i {
							font-size: 12px;
							color: @blue-6;
						}
					}
					.course__organization_name {
						background: @gray-3;
						color: @gray-8;
						border-radius: 4px;
						padding: 1px 8px 1px 8px;
					}
				}
				.next_assignment__overview {
					padding: 24px 24px 24px 16px;
					background: rgba(240, 248, 255, 0.6);
					.next__assignment_end_date_wrapper {
						width: fit-content;
						display: flex;
						align-items: center;
						background: @gray-3;
						border-radius: 4px;
						padding: 2px 8px 2px 8px;
					}

					@media only screen and (max-width: 767px) {
						padding-left: 68px;
					}
				}
			}
			.show__all_assignments_icon {
				width: 56px;
				padding-left: 8px;
				padding-right: 24px;
				height: auto;
				display: flex;
				align-items: center;
			}
		}
		.assignment__details_with_list {
			background: @gray-2;
			box-shadow: inset 0px 1px 0px #e2e8f0;
		}
		.course__assignments_view_all {
			width: 100%;
			padding: 16px 0 16px 0;
			display: flex;
			justify-content: center;
		}
		.assignment__list_item_overview {
			width: 100%;
			padding: 16px 24px 16px 24px;
			display: flex;
			.assignment__overview_content {
				width: calc(100% - 32px);
				display: flex;
				align-items: center;
				.assignment__end_date_overview {
					background: @gray-3;
					border-radius: 4px;
					padding: 2px 8px 2px 8px;
					width: fit-content;
					@media only screen and (max-width: 767px) {
						margin-top: 4px;
						margin-left: 44px;
					}
				}
			}
			.navigate__to_assignment_icon {
				width: 32px;
				padding-left: 16px;
				height: auto;
				display: flex;
				align-items: center;
			}
		}
		.view__all_assignments_toggle {
			width: 100%;
			padding: 8px 42px 8px 24px;
			display: flex;
			justify-content: flex-end;
		}
		.student__assignments_empty_state_wrapper {
			padding: 0 24px 12px 24px;
			@media only screen and (max-width: 575px) {
				.student__assignments_empty_state_space {
					flex-direction: column;
					.ant-space-item:first-of-type {
						margin-right: 0px !important;
					}
				}
			}
		}
	}
}

b, strong {
	font-weight: bold;
}