//
//** Alert Customization
//

//==== Component Mixins ====//

//== Component Base
.akademia-build-extend--alert-base() {
}

//== Component Skin
.akademia-build-extend--alert-skin(@skin) {
	@content: {
		&-success {
			background-color: @gray-1;
			border: 1px solid @green-3;
			.@{alert-prefix-cls}-icon {
				color: @green-6;
			}
		}

		&-info {
			background-color: @blue-1;
			border: 1px solid @blue-3;
			.@{alert-prefix-cls}-icon {
				color: @blue-6;
			}
		}

		&-warning {
			background-color: @gray-1;
			border: 1px solid @gold-3;
			.@{alert-prefix-cls}-icon {
				color: @gold-6;
			}
		}

		&-error {
			background-color: @red-1;
			border: 1px solid @red-3;
			.@{alert-prefix-cls}-icon {
				color: @red-6;
			}
		}

		&-close-icon {
			.@{iconfont-css-prefix}-close {
				color: @gray-8;
				&:hover {
					color: @alert-close-hover-color;
				}
			}
		}

		&-with-description &-message {
			color: @gray-9;
			font-weight: normal;
			.text_font_size_16;
			.text_line_height_24;
		}

		&-message {
			color: @gray-8;
			font-weight: normal;
			.text_font_size_14;
			.text_line_height_24;
		}

		&-description {
			color: @gray-8;
			font-weight: normal;
			.text_font_size_14;
			.text_line_height_22;
		}
	};

	.component-skin(@alert-prefix-cls, @skin, @content);
}

//== Component Base
.akademia-build-extend--alert-base;

//== Component Skin
.akademia-build-extend--alert-skin(default);
