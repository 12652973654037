@media only screen and (max-width: 575px) {
  .collect-data {
    .ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical) .ant-steps-item {
      padding-left: 0!important;
      padding-top: 0!important;
    }

    .ant-modal-body {
      padding-left: 15px;
      padding-right: 15px;
    }

    .ant-space.ant-space-vertical {
      width: 100%;
    }

    .ant-table-thead .ant-table-cell {
      text-align: center;
    }
  }
}
