@media only screen and (max-width: @medium) {
	.lessonMaterial__button__fullWidth {
		width: 100%;
	}
}

.lessonMaterial__uploader {
	&.@{upload-prefix-cls} {
		&&-drag {
			background: @gray-3 !important;
			height: auto;
		}
	}

	&&-icon {
		font-size: 2.5rem !important;
		color: @blue-6 !important;
	}
}

.lessonMaterial__modal_commit {
	width: 100%;

	.ant-upload.ant-upload-select {
		display: none !important;
	}
}
