.header__logo {
	height: 31px;
}

.header__logo img {
	height: 31px;
	object-fit: contain;
}

.header__marketplace_button {
	position: relative;
	width: 193px;
	height: 33px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	padding: 0.5em 1em 0.5em 1em;
	color: #ffffff;
	background: #2063e3;
	background: linear-gradient(to right, #296ceb 40%, #1e2bbb);
	border-radius: 6px;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.7em;
	cursor: pointer;
	overflow: hidden;
}

.header__marketplace_button:hover,
.header__marketplace_button:active {
	color: #ffffff;
}

.header__marketplace_button_text {
	color: @gray-1;
}

.header__marketplace_container {
	padding: 0.75rem 0.5rem;
}
