.course__builder__materialItem__card {
	background-color: @gray-1;	
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	border-radius: 6px;
	width: 100%;
	.ant-card-body {
		height: 0;
	}
	.extra__button {
		border-radius: 50% !important;
		border: 0 !important;
		box-shadow: 0 0 transparent !important;
		background: transparent !important;
	}
}
