.calendar__container {
	.events .ant-badge-status {
		overflow: hidden;
		white-space: nowrap;
		width: 100%;
		text-overflow: ellipsis;
		font-size: 12px;
	}
	.linethrough {
		text-decoration: line-through;
	}
	.calendar-item:hover {
		text-decoration: underline;
	}
}
