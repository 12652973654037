.lessonList__course-view_panel {
    >.ant-collapse-item:last-child {
        box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
        filter: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
        -webkit-filter: drop-shadow(0px 1px 2px rgba(8, 24, 35, 0.06)), drop-shadow(0px 1px 3px rgba(8, 24, 35, 0.1));

        >.ant-collapse-header {
            background: @gray-1;
            box-shadow: inset 0px -1px 0px #cbd5e0;
            filter: inset 0px -1px 0px #cbd5e0;
            -webkit-filter: drop-shadow(inset 0px -1px 0px #cbd5e0);
            border-radius: 6px 6px 0px 0px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            padding-right: 24px;
        }

        >.ant-collapse-content {
            background: @gray-1;
            border-radius: 0px 0px 6px 6px;

            >.ant-collapse-content-box {
                padding: 0;
            }
        }
    }

    & .item-list {
        padding: 16px 24px;
        background: @gray-1;
        box-shadow: inset 0px -1px 0px #e2e8f0;
        border-radius: 0px;
    }

    & .ant-btn-ghost {
        border-radius: 50% !important;
        border: 0 !important;
        box-shadow: 0 0 transparent !important;
        background: transparent !important;
    }

    & [ant-click-animating-without-extra-node='true']::after {
        border: 0 !important;
        box-shadow: 0 0 transparent !important;
        background: transparent !important;
    }
}


.lessonList__course-view_teacher-panel {
    &.ant-collapse {
        background-color: rgba(@gray-1, 0%);
    }

    &.ant-collapse-borderless {
        >.ant-collapse-item {
            border-bottom: none;
        }
    }

    >.ant-collapse-item:last-child {
        >.ant-collapse-header {
            background-color: rgba(@gray-1, 0%);
            border-bottom: 1px solid @blue-6;
            color: @blue-6;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 32px;
        }

        >.ant-collapse-content {
            background-color: rgba(@gray-1, 0%);
            border: none;

            >.ant-collapse-content-box {
                padding: 0;
            }
        }
    }

    & .item-list {
        padding: 16px 24px;
        box-shadow: none;
    }

    & .ant-btn-ghost {
        border-radius: 50% !important;
        border: 0 !important;
        box-shadow: 0 0 transparent !important;
        background: transparent !important;
    }

    & [ant-click-animating-without-extra-node='true']::after {
        border: 0 !important;
        box-shadow: 0 0 transparent !important;
        background: transparent !important;
    }
}

.lessonList__course-view_student-panel {
    &.ant-collapse {
        background-color: rgba(@gray-1, 0%);
    }

    &.ant-collapse-borderless {
        >.ant-collapse-item {
            border-bottom: none;
        }
    }

    >.ant-collapse-item:last-child {
        >.ant-collapse-header {
            background-color: rgba(@gray-1, 0%);
            border-bottom: 1px solid @blue-6;
            color: @blue-6;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 32px;
        }

        >.ant-collapse-content {
            background-color: rgba(@gray-1, 0%);
            border-radius: 0px 0px 6px 6px;

            >.ant-collapse-content-box {
                padding: 0;
            }
        }
    }

    & .item-list {
        padding: 16px 24px;
        box-shadow: none;
    }

    & .ant-btn-ghost {
        border-radius: 50% !important;
        border: 0 !important;
        box-shadow: 0 0 transparent !important;
        background: transparent !important;
    }

    & [ant-click-animating-without-extra-node='true']::after {
        border: 0 !important;
        box-shadow: 0 0 transparent !important;
        background: transparent !important;
    }
}

.lessonList__course-view_teacher-copy-content-panel {
    >.ant-collapse-item:last-child {
        box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
        filter: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
        -webkit-filter: drop-shadow(0px 1px 2px rgba(8, 24, 35, 0.06)), drop-shadow(0px 1px 3px rgba(8, 24, 35, 0.1));

        >.ant-collapse-header {
            background: @gray-1;
            box-shadow: inset 0px -1px 0px #cbd5e0;
            filter: inset 0px -1px 0px #cbd5e0;
            -webkit-filter: drop-shadow(inset 0px -1px 0px #cbd5e0);
            border-radius: 6px 6px 0px 0px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            padding-right: 24px;
        }

        >.ant-collapse-content {
            background: @gray-1;
            border-radius: 0px 0px 6px 6px;

            >.ant-collapse-content-box {
                padding: 0;
            }
        }
    }

    & .item-list {
        padding: 16px 24px;
        background: @gray-1;
        box-shadow: inset 0px -1px 0px #e2e8f0;
        border-radius: 0px;
    }

    & .ant-btn-ghost {
        border-radius: 50% !important;
        border: 0 !important;
        box-shadow: 0 0 transparent !important;
        background: transparent !important;
    }

    & [ant-click-animating-without-extra-node='true']::after {
        border: 0 !important;
        box-shadow: 0 0 transparent !important;
        background: transparent !important;
    }
}

.lessonList__course-view_student-async-list {
    >.ant-collapse-item:last-child {
        box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
        filter: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
        -webkit-filter: drop-shadow(0px 1px 2px rgba(8, 24, 35, 0.06)), drop-shadow(0px 1px 3px rgba(8, 24, 35, 0.1));

        >.ant-collapse-header {
            background: @gray-1;
            box-shadow: inset 0px -1px 0px #cbd5e0;
            filter: inset 0px -1px 0px #cbd5e0;
            -webkit-filter: drop-shadow(inset 0px -1px 0px #cbd5e0);
            border-radius: 6px 6px 0px 0px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            padding-right: 24px;
        }

        >.ant-collapse-content {
            background: @gray-1;
            border-radius: 0px 0px 6px 6px;

            >.ant-collapse-content-box {
                padding: 0;
            }
        }
    }

    & .item-list {
        padding: 16px 24px;
        background: @gray-1;
    }

    .ant-row:hover {
        background-color: @gray-2;
    }
}

.lessonList__course-view_student-material-panel {
    >.ant-collapse-item:last-child {
        box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
        filter: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
        -webkit-filter: drop-shadow(0px 1px 2px rgba(8, 24, 35, 0.06)), drop-shadow(0px 1px 3px rgba(8, 24, 35, 0.1));

        >.ant-collapse-header {
            box-shadow: inset 0px -1px 0px #cbd5e0;
            filter: inset 0px -1px 0px #cbd5e0;
            -webkit-filter: drop-shadow(inset 0px -1px 0px #cbd5e0);
            border-radius: 0px;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            padding-right: 24px;
        }

        >.ant-collapse-content {
            background: @gray-1;
            border-radius: 0px;

            >.ant-collapse-content-box {
                padding: 0;
            }
        }
    }

    & .item-list {
        padding: 16px 24px;
        background: @gray-1;
    }


    .ant-row:hover {
        background-color: @gray-2;
    }

    & .active-item {
        .ant-row:hover {
            background-color: unset;
        }
    }
}
