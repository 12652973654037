.onboarding__layout {
	.layout__content {
		flex: 1;
		display: flex;
		justify-content: center;
		@media only screen and (min-width: 320px) {
			.onboarding__form__content {
				width: 288px;
			}
		}
		@media only screen and (min-width: 576px) {
			.onboarding__form__content {
				width: 393px;
			}
		}
		@media only screen and (min-width: 768px) {
			.onboarding__form__content {
				width: 585px;
			}
		}
		@media only screen and (min-width: 992px) {
			.onboarding__form__content {
				width: 975px;
			}
		}
		@media only screen and (min-width: 1200px) {
			.onboarding__form__content {
				width: 1000px;
			}
		}
		@media only screen and (min-width: 1600px) {
			.onboarding__form__content {
				width: 1256px;
			}
		}
	}
}
