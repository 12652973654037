.onboarding__static__avatars_list {
	height: 456px;
	overflow-y: auto;
	overflow-x: hidden;
	.onboarding__avatar_preview {
		padding-left: 0;
		text-align: center;
		.onboarding__avatar_preview_image {
			display: inline-block;
			position: relative;
			width: 78px;
			height: 78px;
			overflow: hidden;
			border-radius: 50%;
			img {
				width: auto;
				height: 100%;
			}
		}
	}
}

.onboarding__static__avatars_list::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: @gray-1;
}

.onboarding__static__avatars_list::-webkit-scrollbar {
	width: 10px;
	background-color: @gray-1;
}

.onboarding__static__avatars_list::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: rgba(0, 0, 0, 0.5);
}

.onboarding__upload_avatar_preview_wrapper {
	width: 102px;
	height: 102px;
	padding: 8px;
	overflow: hidden;
	border: 1px solid @gray-5;
	box-sizing: border-box;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		width: 100%;
		height: auto;
	}
}
