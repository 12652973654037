.lessonItem__lessonList-button {

    &.ant-btn-ghost {
        border-radius: 50% !important;
        border: 0 !important;
        box-shadow: 0 0 transparent !important;
        background: transparent !important;
    }

    &[ant-click-animating-without-extra-node='true']::after {
        border: 0 !important;
        box-shadow: 0 0 transparent !important;
        background: transparent !important;
    }
}
