.mt-0 {
	margin-top: 0 !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.ml-0 {
	margin-left: 0 !important;
}

.m-0 {
	margin: 0 !important;
}

.mt-4 {
	margin-top: 0.25rem !important;
}

.mr-4 {
	margin-right: 0.25rem !important;
}

.mb-4 {
	margin-bottom: 0.25rem !important;
}

.ml-4 {
	margin-left: 0.25rem !important;
}

.m-4 {
	margin: 0.25rem !important;
}

.mt-6 {
	margin-top: 0.375rem !important;
}

.mr-6 {
	margin-right: 0.375rem !important;
}

.mb-6 {
	margin-bottom: 0.375rem !important;
}

.ml-6 {
	margin-left: 0.375rem !important;
}

.m-6 {
	margin: 0.375rem !important;
}

.mt-8 {
	margin-top: 0.5rem !important;
}

.mr-8 {
	margin-right: 0.5rem !important;
}

.mb-8 {
	margin-bottom: 0.5rem !important;
}

.ml-8 {
	margin-left: 0.5rem !important;
}

.m-8 {
	margin: 0.5rem !important;
}

.mt-12 {
	margin-top: 0.75rem !important;
}

.mr-12 {
	margin-right: 0.75rem !important;
}

.mb-12 {
	margin-bottom: 0.75rem !important;
}

.ml-12 {
	margin-left: 0.75rem !important;
}

.m-12 {
	margin: 0.75rem !important;
}

.mt-16 {
	margin-top: 1rem !important;
}

.mr-16 {
	margin-right: 1rem !important;
}

.mb-16 {
	margin-bottom: 1rem !important;
}

.ml-16 {
	margin-left: 1rem !important;
}

.m-16 {
	margin: 1rem !important;
}

.m16-24 {
	margin: 1rem 1.5rem;
}

.mt-24 {
	margin-top: 1.5rem !important;
}

.mr-24 {
	margin-right: 1.5rem !important;
}

.mb-24 {
	margin-bottom: 1.5rem !important;
}

.ml-24 {
	margin-left: 1.5rem !important;
}

.m-24 {
	margin: 1.5rem !important;
}

.mt-32 {
	margin-top: 2rem !important;
}

.mr-32 {
	margin-right: 2rem !important;
}

.mb-32 {
	margin-bottom: 2rem !important;
}

.ml-32 {
	margin-left: 2rem !important;
}

.m-32 {
	margin: 2rem !important;
}

.mt-40 {
	margin-top: 2.5rem !important;
}

.mr-40 {
	margin-right: 2.5rem !important;
}

.mb-40 {
	margin-bottom: 2.5rem !important;
}

.ml-40 {
	margin-left: 2.5rem !important;
}

.m-40 {
	margin: 2.5rem !important;
}

.mt-48 {
	margin-top: 3rem !important;
}

.mr-48 {
	margin-right: 3rem !important;
}

.mb-48 {
	margin-bottom: 3rem !important;
}

.ml-48 {
	margin-left: 3rem !important;
}

.m-48 {
	margin: 3rem !important;
}

.mt-56 {
	margin-top: 3.5rem !important;
}

.mr-56 {
	margin-right: 3.5rem !important;
}

.mb-56 {
	margin-bottom: 3.5rem !important;
}

.ml-56 {
	margin-left: 3.5rem !important;
}

.m-56 {
	margin: 3.5rem !important;
}

.mt-64 {
	margin-top: 4rem !important;
}

.mr-64 {
	margin-right: 4rem !important;
}

.mb-64 {
	margin-bottom: 4rem !important;
}

.ml-64 {
	margin-left: 4rem !important;
}

.m-64 {
	margin: 4rem !important;
}

.mt-80 {
	margin-top: 5rem !important;
}

.mr-80 {
	margin-right: 5rem !important;
}

.mb-80 {
	margin-bottom: 5rem !important;
}

.ml-80 {
	margin-left: 5rem !important;
}

.m-80 {
	margin: 5rem !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pr-0 {
	padding-right: 0 !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.p-0 {
	padding: 0 !important;
}

.pt-4 {
	padding-top: 0.25rem !important;
}

.pr-4 {
	padding-right: 0.25rem !important;
}

.pb-4 {
	padding-bottom: 0.25rem !important;
}

.pl-4 {
	padding-left: 0.25rem !important;
}

.p-4 {
	padding: 0.25rem !important;
}

.pt-6 {
	padding-top: 0.375rem !important;
}

.pr-6 {
	padding-right: 0.375rem !important;
}

.pb-6 {
	padding-bottom: 0.375rem !important;
}

.pl-6 {
	padding-left: 0.375rem !important;
}

.p-6 {
	padding: 0.375rem !important;
}

.pt-8 {
	padding-top: 0.5rem !important;
}

.pr-8 {
	padding-right: 0.5rem !important;
}

.pb-8 {
	padding-bottom: 0.5rem !important;
}

.pl-8 {
	padding-left: 0.5rem !important;
}

.p-8 {
	padding: 0.5rem !important;
}

.p12-24 {
	padding: 0.75rem 1.5rem !important;
}

.pt-16 {
	padding-top: 1rem !important;
}

.pr-16 {
	padding-right: 1rem !important;
}

.pb-16 {
	padding-bottom: 1rem !important;
}

.pl-16 {
	padding-left: 1rem !important;
}

.p-16 {
	padding: 1rem !important;
}

.p16-24 {
	padding: 1rem 1.5rem !important;
}

.pt-24 {
	padding-top: 1.5rem !important;
}

.pr-24 {
	padding-right: 1.5rem !important;
}

.pb-24 {
	padding-bottom: 1.5rem !important;
}

.pl-24 {
	padding-left: 1.5rem !important;
}

.p-24 {
	padding: 1.5rem !important;
}

.pt-32 {
	padding-top: 2rem !important;
}

.pr-32 {
	padding-right: 2rem !important;
}

.pb-32 {
	padding-bottom: 2rem !important;
}

.pl-32 {
	padding: 2rem !important;
}

.p-32 {
	padding: 2rem !important;
}

.pt-40 {
	padding-top: 2.5rem !important;
}

.pr-40 {
	padding-right: 2.5rem !important;
}

.pb-40 {
	padding-bottom: 2.5rem !important;
}

.pl-40 {
	padding-left: 2.5rem !important;
}

.p-40 {
	padding: 2.5rem !important;
}

.pt-48 {
	padding-top: 3rem !important;
}

.pr-48 {
	padding-right: 3rem !important;
}

.pb-48 {
	padding-bottom: 3rem !important;
}

.pl-48 {
	padding-left: 3rem !important;
}

.p-48 {
	padding: 3rem !important;
}

.pt-56 {
	padding-top: 3.5rem !important;
}

.pr-56 {
	padding-right: 3.5rem !important;
}

.pb-56 {
	padding-bottom: 3.5rem !important;
}

.pl-56 {
	padding-left: 3.5rem !important;
}

.p-56 {
	padding: 3.5rem !important;
}

.pt-64 {
	padding-top: 4rem !important;
}

.pr-64 {
	padding-right: 4rem !important;
}

.pb-64 {
	padding-bottom: 4rem !important;
}

.pl-64 {
	padding-left: 4rem !important;
}

.p-64 {
	padding: 4rem !important;
}

.pt-80 {
	padding-top: 5rem !important;
}

.pr-80 {
	padding-right: 5rem !important;
}

.pb-80 {
	padding-bottom: 5rem !important;
}

.pl-80 {
	padding-left: 5rem !important;
}

.p-80 {
	padding: 5rem !important;
}
