//
//** Dropdown Customization
//

//==== Component Mixins ====//

//== Component Base
.akademia-build-extend--dropdown-base() {
}

//== Component Skin
.akademia-build-extend--dropdown-skin(@skin) {
	@content: {
		&-menu {
			&-item {
				display: flex;
				align-items: center;
			}
		}
	};

	.component-skin(@dropdown-prefix-cls, @skin, @content);
}

//== Component Base
.akademia-build-extend--dropdown-base;

//== Component Skin
.akademia-build-extend--dropdown-skin(default);
