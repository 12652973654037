.materialItem__layout {
	&.materialItem__button-circle {
		border-radius: 50% !important;
	}

	&.materialItem__fixed-widgets {
		position: fixed;
		right: 0px;
		top: 111px;
		z-index: 2;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		cursor: pointer;
	}

	.materialItem__button-over {
		border-radius: 0 !important;
		width: 52px;
		height: 44px;
		border: 1px solid #cbd5e0;
		box-sizing: border-box;
		box-shadow: 0px 2px 4px rgba(8, 24, 35, 0.06), 0px 4px 6px rgba(8, 24, 35, 0.1);
		display: inline-block;

		&.hide {
			display: none;
		}
	}

	.materialItem__icon-over {
		cursor: pointer;
	}

	&.materialItem__sidebar {
		border-left: thin solid #cbd5e0;
		box-shadow: inset 1px 0px 0px #cbd5e0;
		overflow: auto;
	}

	&.materialItem__contentChild {
		overflow: auto;
	}

	&.materialItem__sidebarLayout {
		height: 0;
	}
}
