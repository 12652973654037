.dashboard__teacher_assignments_overview_card {
	border: none;
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	.choose__classroom {
		width: 50%;
		padding: 6px;
	}
	.choose__assignment {
		width: 50%;
		padding: 6px;
	}
	.assignment__status_icon {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.right__divider {
		border-right: 1px solid @gray-3;
	}
	.go__to_assignment {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0px 16px 0px 16px;
		button {
			color: @blue-6;
			border: 1px solid @blue-6;
		}
	}
	.card__extra_next_assignment_end_date {
		display: flex;
		align-items: center;
		padding: 2px 8px 2px 8px;
		background: @gray-3;
		border-radius: 4px;
	}
	.card__extra_next_assignment_end_date_warning {
		background: @red-1;
	}

	@media only screen and (max-width: 575px) {
		.ant-card-head-wrapper {
			flex-direction: column;
			align-items: flex-start;
			.ant-card-head-title {
				padding-bottom: 12px;
			}
			.ant-card-extra {
				margin-left: 0;
				padding-top: 0;
			}
		}
		.choose__classroom {
			width: 100%;
		}
		.choose__assignment {
			width: 100%;
		}
		.right__divider {
			border-right: none;
		}
		.enrolled__students {
			.enrolled__students_space {
				flex-direction: row;
				margin-bottom: 16px;
			}
			.enrolled__students_space .ant-space-item:first-of-type {
				margin-bottom: 0 !important;
				margin-right: 16px;
			}
			.enrolled__students_value_space {
				align-items: flex-start !important;
			}
		}
		.turned__in {
			.turned__in_space {
				flex-direction: row;
				margin-bottom: 16px;
			}
			.turned__in_space .ant-space-item:first-of-type {
				margin-bottom: 0 !important;
				margin-right: 16px;
			}
			.turned__in_value_space {
				align-items: flex-start !important;
			}
		}
		.evaluated__assignments {
			.evaluated__assignments_space {
				flex-direction: row;
				margin-bottom: 16px;
			}
			.evaluated__assignments_space .ant-space-item:first-of-type {
				margin-bottom: 0 !important;
				margin-right: 16px;
			}
			.evaluated__assignments_value_space {
				align-items: flex-start !important;
			}
		}
		.go__to_assignment {
			.ant-space {
				flex-direction: row-reverse;
				.ant-space-item:first-of-type {
					margin-bottom: 0 !important;
				}
				.ant-space-item:nth-of-type(2) {
					margin-right: 6px;
				}
			}
		}
		.teacher__assignments_overview_empty_state_space {
			flex-direction: column;
			.ant-space-item:first-of-type {
				margin-right: 0px !important;
			}
		}
	}

	@media only screen and (max-width: 767px) and (min-width: 576px) {
		.choose__classroom {
			width: 100%;
		}
		.choose__assignment {
			width: 100%;
		}
		.evaluated__assignments {
			border-right: none;
		}
		.go__to_assignment {
			margin-top: 24px;
			.ant-space {
				flex-direction: row-reverse;
				.ant-space-item:first-of-type {
					margin-bottom: 0 !important;
				}
				.ant-space-item:nth-of-type(2) {
					margin-right: 6px;
				}
			}
		}
	}
}
