html,
body {
	height: 100%;
	margin: 0px;
	padding: 0px;
	font-family: @akademia--font-families[regular];
	font-weight: @akademia--font-root[weight];
	.text-size-adjust();
	min-width: 320px;
}

#root {
	height: 100%;
}

@supports (font-variation-settings: normal) {
	html,
	body {
		font-family: @akademia--font-families[regular];
	}
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	.flexbox;
	.flexbox-direction(column);
}
