.intro__message__panel {
	min-height: 100%;
	background: linear-gradient(360deg, #2063e3 0%, #062f96 100%);
	flex-flow: column;
	padding: 64px 32px 0 32px;

	.message__title {
		margin-top: 96px;
		h1 {
			color: @blue-1;
			font-weight: 600;
		}
	}
	.intro__message__panel-logo {
		max-width: 200px;
	}
	.message__description {
		margin-top: 32px;
		h6 {
			color: @blue-2;
		}
	}
	.footer__pattern {
		position: absolute;
		bottom: 0;
		left: 0;
		img {
			width: 100%;
		}
	}
}

@media only screen and (min-width: 992px) {
	.intro__message__panel {
		padding: 64px 32px 0 32px;
	}
}
@media only screen and (min-width: 1200px) {
	.intro__message__panel {
		padding: 64px 80px 0 80px;
	}
}
