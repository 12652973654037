.tutoring__welcome_title {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 5px;
}

.tutoring__welcome_subtitle {
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 20px;
}

.tutoring__welcome_icons {
	font-size: 20px;
	color: @lime-6;
}

.tutoring__icon {
	color: @gray-6;
}

.tutoring__scrollable {
	max-height: 400px;
	overflow-y: auto;
	overflow-x: hidden;

	.form__item__error {
		display: none;
	}
}

.tutoring__no_overflow {
	height: 400px;
	overflow: hidden;
}

.tutoring__ws {
	white-space: pre-line;
}

.tutoring__table_header {
	thead[class*="ant-table-thead"] th {
		background-color: @blue-1 !important;
		color: @blue-6 !important;
		font-size: 12px;
	}
}

.tutoring__grade_icon {
	color: @blue-6;
}
