.subject_items__layout_content {
	display: flex;
	justify-content: center;
}

.subject_items__layout_max_width {
	max-width: 1600px;
	width: 100%;
}

.individual__live_course_card_banner{
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	border-radius: 6px;
	.introduction__cover_content{
		background-color: @blue-1;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 28px 0 28px 0;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}
}