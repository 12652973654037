.flex__center {
	display: flex;
	align-items: center;
}

.flex__column {
	display: flex;
	flex-direction: column;
}

.flex__column_center {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.flex__column_space_between {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.flex__start_space_between {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.flex__center_space_between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.flex__center_justify_center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex__row_center_reverse {
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
}

.flex__space_between {
	display: flex;
	justify-content: space-between;
}

.flex__justify_flex_end {
	display: flex;
	justify-content: flex-end;
}

.justify__flex_end {
	justify-content: flex-end;
}

.text__align_center {
	text-align: center;
}

.full__width {
	width: 100%;
}

.half__width {
	width: 50%;
}

.full__min_height {
	min-height: 100%;
}

.full__height {
	height: 100%;
}

.cursor_pointer {
	cursor: pointer;
}

.cursor_default {
	cursor: default;
}

.ta_right {
	text-align: right;
}

.text_underline {
	text-decoration: underline;
}

.box_shadow {
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
}

.overflow_auto {
	overflow: auto;
}

.whiteSpace-nowrap {
	overflow: hidden;
	white-space: nowrap;
}

.flex__wrap {
	display: flex;
	flex-wrap: wrap;
}