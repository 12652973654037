.formik-field__transition {
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: linear;
	-moz-transition-timing-function: linear;
	-o-transition-timing-function: linear;
	transition-timing-function: linear;
}

.check__box__wrapper {
	background: @gray-1;
	border: 1px solid @gray-5;
	box-sizing: border-box;
	border-radius: @border-radius-base;
	white-space: pre-wrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.check__box__element {
	.formik-field__transition;
	width: 100%;
	padding: 8px 16px 8px 16px;
	font-weight: normal;

	.check__box__wrapper;

	.ant-checkbox-inner {
		border-radius: 2px;
	}

	&.ant-checkbox-wrapper-checked {
		background: @blue-1;
		border: 1px solid @blue-6;
		color: @blue-6;
	}

	&:hover {
		background: @blue-1;
		border: 1px solid @blue-6;
		color: @blue-6;
	}

	&.ant-checkbox-wrapper-disabled {
		background: @gray-3;
		border: 1px solid @gray-5;
		color: @gray-6;
	}
}

.ant-checkbox-group {
	width: 100%;
}

.checkbox__box__element {
	.formik-field__transition;
	width: 100%;
	padding: 24px 0 16px 16px;

	.check__box__wrapper;
	.ant-checkbox {
		position: absolute;
		top: 15px;
		right: 18px;
	}

	span.ant-checkbox + * {
		padding-right: 0;
		padding-left: 0;
	}

	&.ant-checkbox-wrapper-checked {
		background: @blue-1;
		border: 1px solid @blue-6;
		color: @blue-6;
	}

	&:hover {
		background: @blue-1;
		border: 1px solid @blue-6;
		color: @blue-6;
	}

	&.ant-checkbox-wrapper-disabled {
		background: @gray-3;
		border: 1px solid @gray-5;
		color: @gray-6;
	}
}
