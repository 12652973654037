.text_font_size_11 {
	font-size: 0.65rem;
}
.text_font_size_12 {
	font-size: 0.75rem;
}
.text_font_size_14 {
	font-size: 0.875rem;
}
.text_font_size_16 {
	font-size: 1rem;
}
.text_font_size_20 {
	font-size: 1.25rem;
}
.text_font_size_24 {
	font-size: 1.5rem;
}
.text_font_size_30 {
	font-size: 1.875rem;
}
.text_font_size_38 {
	font-size: 2.375rem;
}
.text_font_size_40 {
	font-size: 2.5rem;
}
.text_font_size_46 {
	font-size: 2.875rem;
}
.text_font_size_56 {
	font-size: 3.5rem;
}

.text_line_height_18 {
	line-height: 1rem;
}
.text_line_height_20 {
	line-height: 1.25rem;
}
.text_line_height_22 {
	line-height: 1.375rem;
}
.text_line_height_24 {
	line-height: 1.5rem;
}
.text_line_height_28 {
	line-height: 1.75rem;
}
.text_line_height_32 {
	line-height: 2rem;
}
.text_line_height_38 {
	line-height: 2.375rem;
}
.text_line_height_46 {
	line-height: 2.875rem;
}
.text_line_height_54 {
	line-height: 3.375rem;
}
.text_line_height_64 {
	line-height: 3.5rem;
}

.text_font_wheight_regular {
	font-weight: normal;
}

.text_font_wheight_semibold {
	font-weight: 600;
}
