.marketplace__first__item__card__image {
	width: 100%;
	height: auto;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	border-top-right-radius: 0px;
	background-color: @blue-6;
	background-color: @blue-6;
	height: 100%; //for safari
}

.marketplace__card__image__live__class {
	position: absolute;
	top: 15px;
	right: 15px;
}

.ant-input-search-icon::before {
	border-left: none !important;
}

@media only screen and (max-width: @large) {
	.marketplace__first__item__card__image {
		border-bottom-left-radius: 0px;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}
}

.marketplace__classSlider {
	overflow-x: hidden;
}

.marketplace__classSlider__nav__icon {
	position: absolute;
	z-index: 100;
	border: 1px solid @gray-4;
	border-radius: 24px;
	background-color: @gray-1;
	color: @gray-8;
	height: 32px;
	width: 32px;
	padding: 4px 0px 0px 9px;
}

.marketplace__classSlider__nav__icon__right {
	right: -15px;
}

.marketplace__classSlider__nav__icon__left {
	left: -15px;
}

.marketplace__classSlider__item__card__image {
	width: 100%;
	height: auto;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	background-color: @blue-6;
	height: 100%; //for safari
}

.marketplace__organization__name {
	padding: 4px 6px 4px 6px;
	background-color: @gray-3;
	color: @gray-7;
	border-radius: 4px;
}

.marketplace__full-width-ellipsis {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.marketplace__instructor_banner {
	background-image: url("../../../../../../../images/instructor-banner-bg.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	border-radius: 6px;
	padding: 24px;
	width: 100%;
	height: 153px;
	display: flex;
	justify-content: space-between;

	.instructor_banner__description {
		max-width: 352px;
	}

	.instructor_banner__button {
		background: rgba(255, 255, 255, 0.15);
		border: none;
	}

	@media only screen and (max-width: 650px) {
		flex-direction: column-reverse;
		align-items: flex-start;
		height: auto;

		.instructor_banner__icon {
			margin-bottom: 16px;
			align-self: center;
		}

		& > div {
			display: flex;
			width: 100%;
			flex-direction: column;
			align-items: center;
		}

		.instructor_banner__description {
			text-align: center;
		}
	}
}
