.courseBuilder__courseDetails-studentList {
	&.courseBuilder__button-circle {
		border-radius: 50% !important;
		border: 0 !important;
		box-shadow: 0 0 transparent !important;
		background: transparent !important;
	}
}

.courseBuilder__courseDetails__studentList__btn {
	visibility: hidden;
	transition: none;
}

.listCard__row:hover .courseBuilder__courseDetails__studentList__btn {
	visibility: unset;
}

.ant-table-row:hover .courseBuilder__courseDetails__studentList__btn {
	visibility: unset;
}

.courseBuilder__courseDetails__studentList__btn__cancellAll,
.courseBuilder__courseDetails__studentList__btn__acceptAll {
	border-radius: 20px;
	padding: 0px;
	padding-top: 2px;
	width: 34px;
}

.courseBuilder__courseDetails__studentList__btn__acceptAll,
.courseBuilder__courseDetails__studentList__btn__accept {
	background-color: @green-6;
	color: @gray-1;
}

.courseBuilder__courseDetails__studentList__btn__block {
	border-color: @red-6;
	color: @red-6;
}

.courseBuilder__courseDetails__studentList__menuItem__accept {
	color: @green-6;
}
.courseBuilder__courseDetails__studentList__menuItem__block {
	color: @red-6;
}
