.notification_error {
	background: @red-1;
	border: 1px solid @red-6;
	.ant-notification-notice-icon-error {
		color: @red-6;
	}
}

.notification_success {
	background: @green-1;
	border: 1px solid @green-6;
	.ant-notification-notice-icon-success {
		color: @green-6;
	}
}
