.vertical-steps {
	.override-vertical-steps;
}
.horizontal-steps {
	.override-horizontal-steps;
}

.override-horizontal-steps {
	.ant-steps-item-icon {
		display: none;
	}
	.ant-steps-navigation .ant-steps-item::before {
		position: absolute;
		display: inline-block;
		height: 3px;
		background-color: @primary-color;
		border-radius: 4px;
		-webkit-transition: width 0.3s, left 0.3s;
		transition: width 0.3s, left 0.3s;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
		content: "";
	}
	.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
		color: @primary-color;
	}
	.ant-steps-item-title {
		font-size: 12px;
		line-height: 20px;
		color: @primary-color;
	}
	.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
		color: @text-color;
	}
	.ant-steps-item-process .ant-steps-item-title {
		font-weight: normal;
	}
	.ant-steps
		.ant-steps-item:not(.ant-steps-item-active)
		> .ant-steps-item-container[role="button"]:hover
		.ant-steps-item-title,
	.ant-steps
		.ant-steps-item:not(.ant-steps-item-active)
		> .ant-steps-item-container[role="button"]:hover
		.ant-steps-item-subtitle,
	.ant-steps
		.ant-steps-item:not(.ant-steps-item-active)
		> .ant-steps-item-container[role="button"]:hover
		.ant-steps-item-description {
		color: @primary-color;
	}
}

.override-vertical-steps {
	.ant-steps-item-icon {
		font-family: "Inter";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
	}
	.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
		color: @text-color;
	}
	.ant-steps-item-title {
		font-size: 14px;
		line-height: 22px;
		font-family: "Inter";
		font-style: normal;
		font-weight: normal;
	}
	.ant-steps
		.ant-steps-item:not(.ant-steps-item-active)
		> .ant-steps-item-container[role="button"]:hover
		.ant-steps-item-title,
	.ant-steps
		.ant-steps-item:not(.ant-steps-item-active)
		> .ant-steps-item-container[role="button"]:hover
		.ant-steps-item-subtitle,
	.ant-steps
		.ant-steps-item:not(.ant-steps-item-active)
		> .ant-steps-item-container[role="button"]:hover
		.ant-steps-item-description {
		color: @primary-color;
	}
	.ant-steps
		.ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
		> .ant-steps-item-container[role="button"]:hover
		.ant-steps-item-icon {
		border-color: @primary-color;
	}
	.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: @primary-color;
	}
}
