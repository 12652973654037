@layout-header-padding: 0px 35px;
@layout-header-background: @gray-1;

.@{layout-prefix-cls} {
	min-height: 100vh;
	background-color: @gray-1;

	&-header {
		padding: @space-12 @space-24 @space-12 @space-24;
		background: @gray-1;
		box-shadow: inset 0px -1px 0px @gray-5;
		color: @gray-9;
		line-height: normal;
		height: auto;
	}
	&-sider {
		width: 200px;
		border-right: thin solid @gray-5;
	}
	&-content {
		min-height: 100%;
		background-color: @gray-1;
		padding: 20px;
	}
}
