.design-files__item {
	display: flex;
	flex-flow: column;
	position: relative;
}

.single-file-upload {
	text-align: center;
}

.single-file-upload, .image-input {
	.ant-upload.ant-upload-select.ant-upload-select-picture-card {
		display: flex;
		align-items: center;
		flex-flow: column;
		justify-content: center;
	}
}
