.birthday__field_date {
	width: 33%;
	.ant-select-arrow {
		width: 18px;
		height: 18px;
		font-size: 18px;
		margin-top: -8px;
	}
}

.birthday__field_month {
	width: 34%;
	.ant-select-arrow {
		width: 18px;
		height: 18px;
		font-size: 18px;
		margin-top: -8px;
	}
}

.birthday__field_year {
	width: 33%;
	.ant-select-arrow {
		width: 18px;
		height: 18px;
		font-size: 18px;
		margin-top: -8px;
	}
}
