.users__search__button {
	position: absolute;
	right: 0px;
	border: none;
	border-left: thin solid @gray-5;
	border-top-left-radius: unset;
	border-bottom-left-radius: unset;
	margin-top: 1px;
	height: 30px;
	margin-right: 1px;
}

.users__search__button__xs {
	width: 100%;
}

.user__search__field {
	.ant-btn.ant-btn-loading {
		position: absolute;
	}
}

.users__search__icon {
	position: absolute;
	z-index: 1;
	left: 10px;
	top: 3px;
	color: @gray-6;
	font-size: 16px;
}

.users_search_input {
	padding-left: 35px;
	padding-right: 85px;
}

.users__elipse__small {
	font-size: 10px;
	border-radius: 10px;
	padding: 3px 5px 3px 5px;
}

.users__elipse {
	border-radius: 6px;
	padding: 5px 7px 5px 7px;
}

.admin__activate_account_button {
	border: 1px solid @green-6;
	color: @green-6;
}

.admin__reset_password_button {
	border: 1px solid @gray-5;
	color: @gray-8;
}
