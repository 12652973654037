.course__builder__layout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	font-weight: normal;

	.layout__content {
		flex: 1;
		width: 100%;
		.subject__plan__content__wrapper {
			display: flex;
			justify-content: center;
			width: 256px;
			background-color: @gray-2;
			.subject__plan__content {
				margin-top: 80px;
				margin-left: 10px;
				margin-right: 10px;
				margin-bottom: @space-40;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}
		.node__content {
			flex: 1;
			display: flex;
			background-color: @gray-2;
		}
		.test__collapse {
			flex: 1;
		}
	}
}
