.sider__fold_icon_wrapper {
	position: absolute;
	right: -12px;
	top: 50%;
	box-sizing: border-box;
	background: @gray-1;
	border: 1px solid @gray-5;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	.sider__fold_icon {
		color: @gray-8;
	}
}
.sider__fold_icon_wrapper:hover {
	box-shadow: 0px 2px 4px rgba(8, 24, 35, 0.06), 0px 2px 4px rgba(8, 24, 35, 0.1);
}

.sider__navigation_menu {
	width: 210px !important;
	max-width: 210px !important;
	min-width: 210px !important;
}

.sider__navigation_menu.ant-layout-sider-collapsed {
	width: 80px !important;
	max-width: 80px !important;
	min-width: 80px !important;
}

@media only screen and (max-width: @small) {
	.sider__fold_icon {
		display: none;
	}
}

@media only screen and (max-width: @small) {
	.sider__navigation_menu {
		display: none;
	}
}
