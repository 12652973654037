.dashboard__courses_performance {
	width: 100%;
	background: @gray-1;
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	border-radius: 6px;
	padding: 24px;

	.section__title_wrapper {
		button {
			color: @blue-6;
			border: 1px solid @blue-6;
		}
	}

	@media only screen and (max-width: 575px) {
		padding: 16px;
		.section__title_wrapper {
			width: 100%;
			.ant-space-item:first-of-type {
				margin-bottom: 16px !important;
			}
			button {
				width: 100%;
				margin-bottom: 16px;
			}
		}
		.courses__performance_empty_state_space {
			flex-direction: column;
			.ant-space-item:first-of-type {
				margin-right: 0px !important;
			}
		}
	}

	@media only screen and (max-width: 767px) and (min-width: 576px) {
		padding: 16px;
		.section__title_wrapper {
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
			align-items: center;
			margin-bottom: 16px;
			.ant-space-item:first-of-type {
				margin-bottom: 0 !important;
			}
		}
	}
}
.courses__performance_modal {
	.ant-modal-body {
		padding: 0;
		.course__item_details {
			padding: 16px 24px 16px 24px;
			border-top: 1px solid @gray-4;
			.course__item_details_content {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				.course__icon {
					width: 32px;
					height: 32px;
					border-radius: 50%;
					background-color: @blue-1;
					display: flex;
					justify-content: center;
					align-items: center;
					i {
						color: @blue-6;
					}
				}
				.claculated__score_icon {
					width: 22px;
				}
			}
		}
	}
}
