.individual__course_builder_courses_carousel_card {
	width: 1136px;
	overflow: hidden;
	border: none;
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);

	.row__show_some {
		flex-flow: nowrap !important;
	}

	.row__show_all {
		flex-flow: wrap !important;
	}

	.childs__classSlider_nav_icon {
		position: absolute;
		top: 50%;
		z-index: 1;
	}

	.childs__classSlider_nav_icon_right {
		right: 8px;
	}

	.childs__classSlider_nav_icon_left {
		left: 8px;
	}

	.childs__classSlider_item_card_image {
		width: 100%;
		height: auto;
		border-radius: 6px;
		background-color: @blue-6;
		height: 100%; //for safari
	}
}
