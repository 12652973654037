.choose_nationality__option {
	display: flex;
	flex-direction: column;
	font-weight: normal;
	align-items: flex-start;
	i {
		font-size: 24px;
		background-color: @blue-6;
		border-radius: 4px;
		color: @gray-1;
	}
}
