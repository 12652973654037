.courseBuilder__courseDetails-tab {
    >.ant-tabs-nav {
        background-color: @gray-1;

        >.ant-tabs-extra-content:first-child {
            position: absolute;
            z-index: 1;
        }

        >.ant-tabs-extra-content:last-child {
            position: absolute;
            z-index: 1;
            right: 1%;
        }
    }
}

@media only screen and (max-width: @medium) {
    .courseBuilder__courseDetails-tab {
        >.ant-tabs-nav {
            >.ant-tabs-extra-content:first-child {
                position: relative;
            }

            >.ant-tabs-extra-content:last-child {
                position: relative;
            }
        }
    }
}

.classroom__cover_image {
    width: 100%;
    position: relative;
    padding-bottom: 25%;

    /* ratio 4/1 */
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 6px;
        background-color: @blue-1;
    }

    .no__cover_image {
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 6px;
        background-color: @blue-1;
    }

    span {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
}
