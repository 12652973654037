.dashboard__marketplace_banner {
	background-image: url("../../../../../../../../../../../images/dashboard-marketplace-bg.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	border-radius: 6px;
	padding: 24px;
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-between;
	background-color: var(--background-color);
	color: var(--text-color)!important;
	&.dashboard__marketplace_banner--no-bg {
		background-image: none;
	}
	& * {
		color: var(--text-color)!important;
	}

	.marketplace_banner__description {
		max-width: 352px;
	}

	.marketplace_banner__button {
		background: rgba(255, 255, 255, 0.15);
		border: none;
	}
	.marketplace_banner__custom-icon {
		max-height: 100px;
		margin-top: auto;
		margin-bottom: auto;
	}

	@media only screen and (max-width: 650px) {
		flex-direction: column-reverse;
		align-items: flex-start;
		height: auto;

		.marketplace_banner__icon {
			margin-bottom: 16px;
			align-self: center;
		}

		& > div {
			display: flex;
			width: 100%;
			flex-direction: column;
			align-items: center;
		}

		.marketplace_banner__description {
			text-align: center;
		}
	}

	@media only screen and (min-width: 768px) {
		.marketplace_banner__description {
			max-width: 352px;
		}
	}

	@media only screen and (min-width: 992px) {
		.marketplace_banner__description {
			max-width: 500px;
		}
	}

	@media only screen and (min-width: 1200px) {
		.marketplace_banner__description {
			max-width: 352px;
		}
	}

	@media only screen and (min-width: 1600px) {
		.marketplace_banner__description {
			max-width: 100%;
		}
	}
}
