.account__type__option {
	display: flex;
	flex-direction: column;
	font-weight: normal;
	i {
		font-size: 24px;
		margin-bottom: 8px;
	}
}

.newBadge {
	background-color: @blue-6;
	color: @blue-1;
	border-radius: 40px;
	height: 50px;
	border-radius: 60px;
	display: block;
	padding: 13px 10px 10px 10px;
}

.register--full-container {
	padding-left: 15rem;
	padding-right: 15rem;
}


@media only screen and (max-width: @large) {
	.register--full-container {
		padding-left: 10rem;
		padding-right: 10rem;
	}
}

@media only screen and (max-width: @medium) {
	.register--full-container {
		padding-left: 5rem;
		padding-right: 5rem;
	}
}

@media only screen and (max-width: @small) {
	.register--full-container {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}