.courseBuilder__subjectPlanCreator-menuContent {
    >.ant-menu-item {
        color: @gray-8;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
    }

    >.ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
        color: @gray-8;
    }

    >.ant-menu-item::after {
        border-right: none;
    }

    >.ant-menu-item:active,
    .ant-menu-submenu-title:active {
        background-color: rgba(@gray-2, 0%);
    }

    >.ant-menu {
        background-color: rgba(@gray-2, 0%);
    }

    .ant-menu-item-selected a,
    .ant-menu-item-selected a:hover {
        color: @gray-8;
    }
    
    .ellipsis-text {
        display: block;
        overflow:hidden;
        text-overflow:ellipsis;
        max-width:100%;
    }
}

.courseBuilder__overflow-menu {
    overflow-y: auto;
    height: 800px
}
