.navigation__dot_status {
	width: 8px;
	height: 8px;
	background: @gray-6;
	border-radius: 6px;
}
.navigation__dot_active {
	width: 24px;
}
.navigation__dot_complete,
.navigation__dot_active {
	background: @blue-6;
}
