.parent__dashboard__card {
	width: 80%;
	.ant-card-body {
		padding: 0px;
	}
}

.parent__dashboard__card__children {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.parent__dashboard__card__children__content {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-top: none;
}

@media only screen and (max-width: @large) {
	.parent__dashboard__card {
		width: 100%;
	}
}
