.dashboard__lessons_analytics_card {
	border: none;
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	@media only screen and (max-width: 575px) {
		.ant-card-body {
			padding: 16px;
		}
		.lessons__analytics_empty_state_space {
			flex-direction: column;
			.ant-space-item:first-of-type {
				margin-right: 0px !important;
			}
		}
	}
	.pie__analytics {
		align-items: center;
		canvas {
			float: right;
		}
		@media only screen and (max-width: 991px) {
			canvas {
				width: 100px !important;
				height: 100px !important;
			}
		}
		@media only screen and (min-width: 992px) {
			canvas {
				width: 170px !important;
				height: 170px !important;
			}
		}
	}
	.week__interval {
		padding: 3px 10px 3px 10px;
		border: 1px solid @gray-5;
		box-sizing: border-box;
		border-radius: 5px;
	}
}
