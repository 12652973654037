.individual__course_builder_header_menu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: @gray-1;
	border-bottom: 1px solid @gray-5;
	padding: 16px 32px 16px 32px;
}

.individual__course_builder_course_overview_card {
	width: 1136px;
	.ant-card-head {
		height: 240px;
		max-height: 240px;
		overflow: hidden;
		background-color: @blue-6;
		padding: 0;
		.ant-card-head-title {
			padding: 0 0 0 32px;
			.individual__classroom_cover_image {
				height: 240px;
				max-width: 345px;
				> img {
					height: 100%;
				}
			}
			@media only screen and (max-width: 767px) {
				.individual__classroom_cover_image {
					display: none;
				}
			}
		}
	}
	.ant-card-body {
		padding: 0;
		@media only screen and (max-width: 767px) {
			.individual__classroom_collapse_info {
				.individual__classroom_collapse_extra_info {
					align-items: flex-start;
					flex-direction: column;
				}
			}
		}
	}
}

.update-live-course-card {
	.ant-card-body {
		padding-top: 31px;
	}

	.upload-photo {
		margin-bottom: 40px;

		.title {
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;
			color: #4a5568;
			margin-bottom: 2px;
		}

		.description {
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;
			color: #718096;
			margin-bottom: 8px;
		}

		.upload-button {
			margin-bottom: 8px;
		}
	}

	.cover-photo {
		margin-bottom: 40px;
		.image {
			width: calc(100% + 48px);
			margin-right: -24px;
			margin-left: -24px;
			margin-top: -31px;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}

		.remove-cover-photo {
			position: absolute;
			right: 0px;
			top: -7px;
			color: white;
			width: 35px;
			height: 35px;
			line-height: 35px;
			padding-left: 9px;
			font-size: 17.5px;
			background: rgba(0, 0, 0, 0.4);
			border-radius: 2px;
			cursor: pointer;
			&:hover {
				background: rgba(255, 255, 255, 0.4);
				color: black;
			}
			&:active {
				transform: translateY(1px);
			}
		}

		.edit-cover-photo {
			position: absolute;
			right: 43px;
			top: -7px;
			color: white;
			width: 35px;
			height: 35px;
			line-height: 35px;
			padding-left: 9px;
			font-size: 17.5px;
			background: rgba(0, 0, 0, 0.4);
			border-radius: 2px;
			cursor: pointer;
			&:hover {
				background: rgba(255, 255, 255, 0.4);
				color: black;
			}
			&:active {
				transform: translateY(1px);
			}
		}
	}
	.uploaded-image-preview {
		border: 1px solid #cbd5e0;
		box-sizing: border-box;
		border-radius: 6px;
		display: flex;
		align-items: center;
		column-gap: 8px;
		padding: 8px;
		margin-top: 8px;
		position: relative;

		.image {
			width: 48px;
			height: 48px;
			border-radius: 6px;
			object-fit: cover;
		}

		.image-name {
			margin: 0;
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;
			color: #2063e3;
		}

		.remove-image {
			position: absolute;
			right: 8px;
			top: 8px;
			color: #718096;
			&:before {
				font-size: 17px;
				cursor: pointer;
			}
		}
	}
}
