.zoom__info_modal {
	.ant-modal-body {
		display: flex;
		flex-direction: column;
		align-items: center;
		.zoom__info_modal_icon {
			font-size: 40px;
			line-height: 40px;
		}
	}
}
