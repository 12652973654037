.dashboard__welcome_banner {
	background: @gray-1;
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	border-radius: 6px;
	padding: 24px 24px 23px 24px;
	width: 100%;
	display: flex;
	justify-content: space-between;


	.welcome__description {
		max-width: 352px;
	}

	.welcome_icon {
		display: block;
		bottom: 0;
		right: 0;
		max-height: 160px;
		max-width: 220px;
		position: relative!important;
		margin-left: 15px;
	}

	@media only screen and (min-width: 320px) {
		height: auto;
		.welcome__description {
			max-width: 200px;
		}

		.welcome_icon {
			height: 66px;
			bottom: 30%;
			margin-right: -25px;
		}
	}

	@media only screen and (min-width: 576px) {
		height: auto;
		.welcome__description {
			max-width: 254px;
		}

		.welcome_icon {
			height: 145px;
			bottom: 18px;
			margin-right: -52px;
		}
	}

	@media only screen and (min-width: 768px) {
		.welcome__description {
			max-width: 352px;
		}
		.welcome_icon {
			display: block;
			position: absolute;
			bottom: 0;
			right: 0;
			margin-right: 0;
			height: auto;
		}
	}

	@media only screen and (min-width: 992px) {
		.welcome__description {
			max-width: 500px;
		}
	}

	@media only screen and (min-width: 1200px) {
		.welcome__description {
			max-width: 352px;
		}
	}

	@media only screen and (min-width: 1600px) {
		.welcome__description {
			max-width: 100%;
		}
	}
}
