h1 {
	font-size: @h1-font-size;
	line-height: @h1-line-height;
	margin-bottom: 0;
	font-weight: normal;
}
h2 {
	font-size: @h2-font-size;
	line-height: @h2-line-height;
	margin-bottom: 0;
	font-weight: normal;
}
h3 {
	font-size: @h3-font-size;
	line-height: @h3-line-height;
	margin-bottom: 0;
	font-weight: normal;
}
h4 {
	font-size: @h4-font-size;
	line-height: @h4-line-height;
	margin-bottom: 0;
	font-weight: normal;
}
h5 {
	font-size: @h5-font-size;
	line-height: @h5-line-height;
	margin-bottom: 0;
	font-weight: normal;
}
h6 {
	font-size: @h6-font-size;
	line-height: @h6-line-height;
	margin-bottom: 0;
	font-weight: normal;
}
