.saleAlert {
	z-index: 9999;
	border: none;
	padding: 10px !important;
	background: linear-gradient(90.33deg, #7c00dc -3.86%, #ff25a0 51.87%, #ff473f 108.44%);
}

.saleAlert__fixed {
	top: 0;
	left: 0;
	right: 0;
	padding: 10px !important;
	border: none;
	position: fixed;
	background: linear-gradient(90.33deg, #7c00dc -3.86%, #ff25a0 51.87%, #ff473f 108.44%);
}

.saleAlert__content {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
}

.saleAlert__link {
	color: @gray-1;
	text-decoration: underline;
	cursor: pointer;
}

.saleAlert__message {
	max-width: 70%;
}
