@size: 32px;
.subject__type_icon_content {
	min-width: @size;
	width: @size;
	height: @size;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

@smallSize: 20px;
.subject__type_icon_content__small {
	min-width: @smallSize;
	width: @smallSize;
	height: @smallSize;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}