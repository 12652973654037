.fullScreenMenu {
	top: 56px;
	position: fixed;
	width: 100%;
	height: calc(100% - 56px);
	background-color: @gray-1;
	z-index: 9999;
	overflow-y: auto;
}

.mobile__view__organization_info {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 0 8px 8px 8px;
	.organization__avatar {
		border: 1px solid @blue-3;
		background-color: @blue-1;
	}
	.organization__role_title_content {
		background: @blue-1;
		border-radius: 6px;
		padding: 0px 8px;
	}
	.go__to_manage_button {
		width: 100%;
		justify-content: center;
		cursor: pointer;
		padding: 8px 0 8px 0;
	}
	.go__to_manage_button:hover {
		background: @blue-1;
		border-radius: 6px;
		i,
		span {
			color: @blue-6;
		}
	}
}
