.calendar__with_lessons_wrapper {
	width: 100%;
	.calendar__lessons_wrapper {
		max-height: 1000px;
		overflow-y: auto;
	}

	.calendar__lessons_wrapper::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		background-color: @gray-1;
	}

	.calendar__lessons_wrapper::-webkit-scrollbar {
		width: 10px;
		background-color: @gray-1;
	}

	.calendar__lessons_wrapper::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: rgba(0, 0, 0, 0.5);
	}

	.calendar__component {
		.ant-picker-panel {
			background-color: @gray-2;
			.ant-picker-content {
				thead {
					th {
						color: @volcano-6;
					}
				}
				tbody {
					.ant-picker-cell-selected {
						.ant-picker-calendar-date {
							border-radius: 50%;
						}
					}
					.ant-picker-cell-today {
						.ant-picker-cell-inner {
							&::before {
								border: none !important;
							}
						}
					}
					.ant-picker-calendar-date-content {
						.badge__dot {
							width: 6px;
							height: 6px;
							border-radius: 50%;
							background-color: @volcano-6;
							position: absolute;
							left: 50%;
							margin-top: -3px;
							margin-left: -3px;
						}
					}
				}
			}
		}
	}

	.lesson__overview_by_type {
		width: 100%;
		background: @gray-1;
		box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
		border-radius: 6px;
		padding: 16px;
		cursor: pointer;
		.lesson__item_details {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}
		.ant-divider {
			margin: 8px 0;
		}
		&:hover {
			box-shadow: 0px 2px 4px rgba(8, 24, 35, 0.06), 0px 4px 6px rgba(8, 24, 35, 0.1);
		}
	}

	@media only screen and (max-width: 767px) {
		.calendar__lessons_wrapper {
			max-height: 240px;
		}
	}

	@media only screen and (max-width: 991px) and (min-width: 768px) {
		.calendar__lessons_wrapper {
			padding-left: 24px;
			max-height: 350px;
		}
	}
}
