.courseBuilder__quizMaterial {
	height: 100%;

	.divider {
		height: 100%;
	}
}

.html__with_reverse_toolbar {
	display: flex;
	flex-direction: column;
	flex-flow: column-reverse;
	.ql-toolbar {
		border-radius: 0 0 6px 6px;
	}
	.ql-container {
		border-radius: 6px 6px 0 0;
		border-top: 1px solid @gray-5 !important;
		border-bottom: 0 !important;
		.ql-tooltip {
			left: 0 !important;
		}
	}
}

.quiz__add_question_wrapper {
	.quiz__add_question_upload_photo {
		position: absolute;
		right: 16px;
		top: 28px;
	}
}

.quiz__add_question_drawer {
	.ant-drawer-content-wrapper {
		width: 100% !important;
		max-width: 600px;
	}
}

.quiz__add_option_wrapper {
	.quiz__add_option_upload_photo {
		position: absolute;
		right: 16px;
		top: 28px;
	}
	.quiz__add_option_add_button {
		position: absolute;
		bottom: 24px;
		right: 16px;
		padding: 0;
	}
}

.quiz__crud_image_preview {
	background: @gray-1;
	border: 1px solid @gray-5;
	border-radius: 6px;
	padding: 8px;
	display: flex;
	justify-content: space-between;
	.image_preview {
		width: 48px;
		max-height: 48px;
		border-radius: 6px;
	}
}

.quiz__crud_cover_image_preview {
	.image_preview {
		max-width: 288px;
		max-height: 162px;
	}
}
