@import "./components/index.less";

.dashboard__wrapper {
	display: flex;
	justify-content: center;
	.dashboard__wrapper_content {
		padding: 22px 32px 40px 32px;
	}

	@media only screen and (min-width: 320px) {
		.dashboard__wrapper_content {
			width: 100%;
			padding: 16px;
		}
	}

	@media only screen and (min-width: 576px) {
		.dashboard__wrapper_content {
			width: 100%;
			padding: 24px;
		}
	}

	@media only screen and (min-width: 768px) {
		.dashboard__wrapper_content {
			width: 100%;
			padding: 24px;
		}
	}

	@media only screen and (min-width: 992px) {
		.dashboard__wrapper_content {
			width: 100%;
			padding: 24px;
		}
	}

	@media only screen and (min-width: 1200px) {
		.dashboard__wrapper_content {
			width: 100%;
			padding: 24px;
		}
	}

	@media only screen and (min-width: 1600px) {
		.dashboard__wrapper_content {
			width: 100%;
			padding: 24px 31px 24px 31px;
		}
	}

	@media only screen and (min-width: 2000px) {
		.dashboard__wrapper_content {
			width: 1700px;
			padding: 24px 31px 24px 31px;
		}
	}
}
