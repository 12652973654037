@iterations: 10;
.color-loop (@i, @c) when (@i > 0) {
	.color-@{c}-@{i} {
		color: ~"@{@{c}-@{i}}";
	}
	.background-color-@{c}-@{i} {
		background-color: ~"@{@{c}-@{i}}" !important;
	}
	.color-loop(@i - 1, @c);
}
.color-loop (@iterations, blue);
.color-loop (@iterations, gray);
.color-loop (@iterations, red);
.color-loop (@iterations, volcano);
.color-loop (@iterations, orange);
.color-loop (@iterations, gold);
.color-loop (@iterations, yellow);
.color-loop (@iterations, lime);
.color-loop (@iterations, green);
.color-loop (@iterations, cyan);
.color-loop (@iterations, geekblue);
.color-loop (@iterations, purple);
.color-loop (@iterations, magenta);
