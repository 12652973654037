.invite__students_drawer {
	.ant-drawer-content-wrapper {
		width: 408px !important;
	}
	.invite__students_content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 100%;
		.student__email_input {
			display: flex;
			flex-direction: row;
			width: 100%;
			div {
				width: 100%;
			}
		}
	}
}

@media only screen and (max-width: @small) {
	.invite__students_drawer {
		.ant-drawer-content-wrapper {
			width: 100% !important;
		}
	}
}
