.login__join_instagram_alert {
	background-color: @blue-6;
	border: none;
	.aler__content_wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	@media only screen and (max-width: @small) {
		.aler__content_wrapper {
			flex-direction: column;
		}
	}
	.ri-information-fill {
		color: @gray-1;
	}
	.join__instagram_link {
		color: @gray-1;
		text-decoration: underline;
	}
	.ant-alert-close-icon {
		.anticon-close {
			color: @gray-1;
		}
	}
	.join__instagram_button {
		border: 1px solid @gray-1;
		margin-right: 16px;
		color: @gray-1;
	}
}
