//
//** Button Customization
//

//==== Component Mixins ====//

//== Component Base
.akademia-build-extend--button-base() {
}

//== Component Skin
.akademia-build-extend--button-skin(@skin) {
	@content: {
		align-items: center;
		border-radius: 0.33rem;

		&-circle,
		&-circle-outline {
			display: inline-block;
			.btn-circle(@btn-prefix-cls);
		}

		&-lg {
			border-radius: 0.45rem;
		}

		&-sm {
			border-radius: 0.25rem;
		}

		&-loading-icon {
			padding-right: 8px;
			.anticon {
				padding-right: 0px !important;
			}
		}
	};

	.component-skin(@btn-prefix-cls, @skin, @content);
}

//== Component Base
.akademia-build-extend--button-base;

//== Component Skin
.akademia-build-extend--button-skin(default);
