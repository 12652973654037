.classroom__introdaction_modal {
	width: 480px !important;
	.ant-modal-body {
		padding: 24px 0 24px 0;
		.classroom__main_info {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding-left: 24px;
			padding-right: 24px;
			.section__info {
				width: 204px;
			}
		}
		.classroom__welcome_info {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-left: 24px;
			padding-right: 24px;
		}
	}
}

.asynch__classroom__introdaction_modal {
	width: 480px !important;
	.ant-modal-body {
		padding: 0;
		.introduction__cover_content {
			background-color: @blue-1;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 28px 0 28px 0;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}
	}
}
