.itemFilters__selected__filter {
	color: @gray-8;
	background-color: @gray-9;
	color: @gray-1;
	border-radius: 5px;
}

.itemFilters__selected__filter__small {
	padding-left: 6px;
	padding-right: 6px;
	font-size: 12px;
}
