.ant-layout-sider-collapsed {
	.create__classroom_by_type_button .ant-space-item:nth-child(1) {
		margin-right: 0 !important;
	}
	.create__classroom_by_type_button .ant-space-item:nth-child(2) {
		display: none;
	}
}

.create__classroom_drawer {
	.ant-drawer-body {
		padding: 0 !important;
	}
}
