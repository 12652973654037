.@{menu-prefix-cls} {
	&-inline,
	&-vertical,
	&-vertical-left {
		border-right: none;
	}

	&-inline-collapsed {
		border-right: 1px solid #cbd5e0;
	}

	&-vertical,
	&-vertical-left,
	&-vertical-right,
	&-inline {
		.@{menu-prefix-cls}-item {
			&::after {
				border-right: 1px solid #cbd5e0;
			}
			border-radius: 6px;
		}
		.@{menu-prefix-cls}-item-only-child {
			margin-bottom: 0 !important;
		}
	}
}
