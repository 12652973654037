.dashboard__digitalize_student_banner {
	background-image: url("../../../../../../../../../../../images/dashboard-digitalize-bg.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	border-radius: 6px;
	padding: 24px;
	width: 100%;
	height: 153px;
	display: flex;
	justify-content: space-between;

	.digitalizeStudent__description {
		max-width: 352px;
	}

	.digitalizeStudent__button {
		background: rgba(255, 255, 255, 0.15);
		border: none;
	}

	.digitalizeStudent__divider {
		background: @gray-4;
		height: 40px;
	}

	@media only screen and (max-width: 650px) {
		flex-direction: column-reverse;
		align-items: flex-start;
		height: auto;

		.digitalizeStudent__icon {
			margin-bottom: 16px;
			align-self: center;
		}

		.digitalizeStudent__municipality_logo {
			width: 100%;
		}
	}

	@media only screen and (min-width: 768px) {
		.digitalizeStudent__description {
			max-width: 352px;
		}
	}

	@media only screen and (min-width: 992px) {
		.digitalizeStudent__description {
			max-width: 500px;
		}
	}

	@media only screen and (min-width: 1200px) {
		.digitalizeStudent__description {
			max-width: 352px;
		}
	}

	@media only screen and (min-width: 1600px) {
		.digitalizeStudent__description {
			max-width: 100%;
		}
	}
}

.digitalizeStudent_modal__welcome_title {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 5px;
}

.digitalizeStudent_modal__welcome_subtitle {
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 20px;
}

.digitalizeStudent_modal__icon {
	color: @gray-6;
}

.digitalizeStudent_modal__ws {
	white-space: pre-line;
}

.digitalizeStudent__personal_fields {
	div div {
		width: 100%;
	}
}
