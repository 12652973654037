@headerHeight: 85px;
@footerHeight: 85px;


.full-screen-modal {
  z-index: 1000;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.full-screen-modal__head {
  height: @headerHeight;
}
.full-screen-modal__body {
  height: calc(100% - (@headerHeight + @footerHeight));
  overflow: scroll;

  .modal-item-filters__body .ant-card-body {
    background-color: #fff;
  }
}

.full-screen-modal__footer {
  height: @footerHeight;
}
