.child__statistics__no__bottom__border__radius {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.child__statistics__no__top__border {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-top: none;
	.ant-list {
		width: 100%;
		width: -moz-available; /* WebKit-based browsers will ignore this. */
		width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
		width: fill-available;
	}
}

.child__statistics__left__border {
	padding-left: 24px;
	border-left: thin solid @gray-4;
}

.child__statistics__no__padding {
	.ant-card-body {
		padding-top: 0px;
		padding-bottom: 0px;
	}
	img {
		margin-top: -10px;
	}
}

@media only screen and (max-width: @large) {
	.child__statistics__left__border {
		padding-left: 0px;
		border-left: none;
	}
}

.parent__statistics__classSlider__card {
	width: 100%;
	overflow-x: hidden;
	.ant-card-body {
		padding: 0px;
	}
	.ant-row {
		flex-flow: nowrap;
	}
}

.parent__statistics__classSlider__nav__icon {
	position: absolute;
	z-index: 1;
	border: 1px solid @gray-4;
	border-radius: 71px;
	background-color: @gray-1;
	color: @gray-8;
	height: 30px;
	width: 30px;
	padding-left: 7px;
	padding-top: 3px;
}

.parent__statistics__classSlider__nav__icon__right {
	right: 10px;
}

.parent__statistics__classSlider__nav__icon__left {
	left: 10px;
}

.parent__statistics__classSlider__item__card__image {
	width: 100%;
	height: auto;
	border-radius: 6px;
	background-color: @blue-6;
	height: 100%; //for safari
}

.parent__statistics__virtualClass__item {
	bottom: 0%;
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	border-radius: 6px;
}
