.settings__change_password {
	width: 100%;
	display: flex;
	justify-content: center;
	.change__password_image_preview {
		background-color: @blue-1;
		border-radius: 6px;
		text-align: center;
		img {
			margin: 66px 0 66px 0;
		}
	}
}

@media only screen and (min-width: 320px) {
	.settings__change_password_card {
		width: 100%;
	}
}
@media only screen and (min-width: 576px) {
	.settings__change_password_card {
		width: 100%;
	}
}
@media only screen and (min-width: 768px) {
	.settings__change_password_card {
		width: 100%;
	}
}
@media only screen and (min-width: 992px) {
	.settings__change_password_card {
		width: 100%;
	}
}
@media only screen and (min-width: 1200px) {
	.settings__change_password_card {
		width: 936px;
	}
}
