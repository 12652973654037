.meeting__task_card {
	&:hover {
		box-shadow: 0px 2px 4px rgba(8, 24, 35, 0.06), 0px 4px 6px rgba(8, 24, 35, 0.1);
	}
	.ant-card-body {
		padding: 16px;
		display: flex;
		flex-direction: row;
		.meeting__task_info {
			display: flex;
			flex-direction: column;
		}
	}
}

.assignment__task_card {
	&:hover {
		box-shadow: 0px 2px 4px rgba(8, 24, 35, 0.06), 0px 4px 6px rgba(8, 24, 35, 0.1);
	}
	.ant-card-body {
		padding: 16px;
		.assignment__task_card_info {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			padding-right: 16px;
			.subject__type_icon_content {
				margin-right: 12px;
			}
			.assignment__info {
				display: flex;
				flex-direction: column;
			}
		}
		.task__redirect_icon {
			position: absolute;
			top: 16px;
			right: 16px;
		}
	}
}

.video__task_card {
	&:hover {
		box-shadow: 0px 2px 4px rgba(8, 24, 35, 0.06), 0px 4px 6px rgba(8, 24, 35, 0.1);
	}
	.ant-card-body {
		padding: 16px;
		.video__task_card_info {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			padding-right: 16px;
			.subject__type_icon_content {
				margin-right: 12px;
			}
			.video__info {
				display: flex;
				flex-direction: column;
			}
		}
		.task__redirect_icon {
			position: absolute;
			top: 16px;
			right: 16px;
		}
	}
}

.quiz__task_card {
	&:hover {
		box-shadow: 0px 2px 4px rgba(8, 24, 35, 0.06), 0px 4px 6px rgba(8, 24, 35, 0.1);
	}
	.ant-card-body {
		padding: 16px;
		.quiz__task_card_info {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			padding-right: 16px;
			.subject__type_icon_content {
				margin-right: 12px;
			}
			.quiz__info {
				display: flex;
				flex-direction: column;
			}
		}
		.task__redirect_icon {
			position: absolute;
			top: 16px;
			right: 16px;
		}
	}
}
