.choose__role_option_card {
	padding: 12px 16px 12px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: @gray-1;
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	border-radius: 6px;
	cursor: pointer;
	&:hover {
		box-shadow: 0px 2px 4px rgba(8, 24, 35, 0.06), 0px 4px 6px rgba(8, 24, 35, 0.1);
		.text {
			color: @blue-6;
		}
	}
}
