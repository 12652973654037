.async__courses_analytics {
	width: 100%;
	background-color: @gray-1;
	border-radius: 6px;
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	display: flex;
	flex-direction: row;
	.courses__analytics_content {
		width: 50%;
		border-right: 1px solid @gray-4;
		padding: 16px 35px 24px 24px;
	}
	.courses__analytics_lessons {
		display: flex;
		flex-direction: column;
		width: 50%;
		.videos__analytics {
			padding: 40px 24px 40px 24px;
			border-bottom: 1px solid @gray-4;
			.subject__type_icon_content {
				width: 40px !important;
				height: 40px !important;
			}
		}
		.quizes__analytics {
			padding: 24px;
			.subject__type_icon_content {
				width: 40px !important;
				height: 40px !important;
			}
		}
	}
}

@media only screen and (min-width: 320px) {
	.async__courses_analytics {
		flex-direction: column;
		.courses__analytics_content {
			width: 100%;
			border-right: none;
			border-bottom: 1px solid @gray-4;
		}
		.courses__analytics_lessons {
			width: 100%;
		}
	}
}

@media only screen and (min-width: 576px) {
	.async__courses_analytics {
		flex-direction: row;
		.courses__analytics_content {
			width: 50%;
			border-right: 1px solid @gray-4;
			border-bottom: none;
		}
		.courses__analytics_lessons {
			width: 50%;
		}
	}
}

@media only screen and (min-width: 768px) {
	.async__courses_analytics {
		flex-direction: row;
		.courses__analytics_content {
			width: 50%;
			border-right: 1px solid @gray-4;
			border-bottom: none;
		}
		.courses__analytics_lessons {
			width: 50%;
		}
	}
}

@media only screen and (min-width: 992px) {
	.async__courses_analytics {
		flex-direction: row;
		.courses__analytics_content {
			width: 50%;
			border-right: 1px solid @gray-4;
			border-bottom: none;
		}
		.courses__analytics_lessons {
			width: 50%;
		}
	}
}

@media only screen and (min-width: 1200px) {
	.async__courses_analytics {
		flex-direction: row;
		.courses__analytics_content {
			width: 50%;
			border-right: 1px solid @gray-4;
			border-bottom: none;
		}
		.courses__analytics_lessons {
			width: 50%;
		}
	}
}

@media only screen and (min-width: 1600px) {
	.async__courses_analytics {
		flex-direction: row;
		.courses__analytics_content {
			width: 50%;
			border-right: 1px solid @gray-4;
			border-bottom: none;
		}
		.courses__analytics_lessons {
			width: 50%;
		}
	}
}
