@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url("@{font-path}inter_thin/Inter-Thin.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_thin/Inter-Thin.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 100;
	font-display: swap;
	src: url("@{font-path}inter_thin_italic/Inter-ThinItalic.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_thin_italic/Inter-ThinItalic.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url("@{font-path}inter_extralight/Inter-ExtraLight.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_extralight/Inter-ExtraLight.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 200;
	font-display: swap;
	src: url("@{font-path}inter_extralight_italic/Inter-ExtraLightItalic.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_extralight_italic/Inter-ExtraLightItalic.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url("@{font-path}inter_light/Inter-Light.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_light/Inter-Light.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url("@{font-path}inter_light_italic/Inter-LightItalic.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_light_italic/Inter-LightItalic.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("@{font-path}inter_regular/Inter-Regular.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_regular/Inter-Regular.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url("@{font-path}inter_italic/Inter-Italic.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_italic/Inter-Italic.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("@{font-path}inter_medium/Inter-Medium.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_medium/Inter-Medium.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url("@{font-path}inter_medium_italic/Inter-MediumItalic.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_medium_italic/Inter-MediumItalic.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url("@{font-path}inter_semibold/Inter-SemiBold.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_semibold/Inter-SemiBold.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url("@{font-path}inter_semibold_italic/Inter-SemiBoldItalic.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_semibold_italic/Inter-SemiBoldItalic.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("@{font-path}inter_bold/Inter-Bold.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_bold/Inter-Bold.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url("@{font-path}inter_bold_italic/Inter-BoldItalic.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_bold_italic/Inter-BoldItalic.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url("@{font-path}inter_extrabold/Inter-ExtraBold.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_extrabold/Inter-ExtraBold.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 800;
	font-display: swap;
	src: url("@{font-path}inter_extrabold_italic/Inter-ExtraBoldItalic.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_extrabold_italic/Inter-ExtraBoldItalic.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url("@{font-path}inter_black/Inter-Black.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_black/Inter-Black.woff@{font-version-path}") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 900;
	font-display: swap;
	src: url("@{font-path}inter_black_italic/Inter-BlackItalic.woff2@{font-version-path}") format("woff2"),
		url("@{font-path}inter_black_italic/Inter-BlackItalic.woff@{font-version-path}") format("woff");
}

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
	font-family: "Inter var";
	font-weight: 100 900;
	font-display: swap;
	font-style: normal;
	font-named-instance: "Regular";
	src: url("@{font-path}inter_roman/Inter-roman.var.woff2@{font-version-path}") format("woff2");
}

@font-face {
	font-family: "Inter var";
	font-weight: 100 900;
	font-display: swap;
	font-style: italic;
	font-named-instance: "Italic";
	src: url("@{font-path}inter_italic/Inter-italic.var.woff2@{font-version-path}") format("woff2");
}

/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
	font-family: "Inter var experimental";
	font-weight: 100 900;
	font-display: swap;
	font-style: oblique 0deg 10deg;
	src: url("@{font-path}Inter.var.woff2@{font-version-path}") format("woff2");
}
