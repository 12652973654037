@import "./Select.less";
@import "./FormItemError.less";
@import "./HtmlInput.less";

// //overrides  ant input focus styles
// .focus-visible {
// 	border-right-width: 0 !important;
// 	box-shadow: none !important;
// 	background: none !important;
// }

// .input-wrapper {
// 	border-bottom: 1px solid @border-color-base;
// 	margin-left: 11px;
// }

// .input-wrapper_with_error {
// 	border-bottom: 1px solid @red-6;
// }

// .disabled-input-wrapper {
// 	margin-left: 11px;
// }

// .disabled-formik-field {
// 	border: 1px solid @border-color-base;
// }

// .ant-upload-picture-card-wrapper {
// 	display: block !important;
// }

.error_input {
	// &.ant-input {
	// 	border: 1px solid @red-6 !important;
	// }

	// &.ant-input-number-input-wrap {
	// 	border: 2px solid @error-color !important;
	// }
	border: 1px solid @red-6;
}
