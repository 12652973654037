.big-layout__empty-bg {
  width: 100%;
  height: 100vh;
  background-color: #000;
}

.big-layout__logo {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}