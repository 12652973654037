.formik_html_input {
	div[data-mode="formula"] {
		z-index: 99999;
	}
	.ql-toolbar {
		border-radius: 6px 6px 0 0;
		background-color: @gray-2;
	}
	.ql-container {
		border-radius: 0 0 6px 6px;
	}
}
