@newsfeeder__card__border__radius: 6px;

.newsfeeder__card__border_top {
	border-top: 1px solid @gray-5;
}
.newsfeeder__card__border_right {
	border-right: 1px solid @gray-5;
}
.newsfeeder__card__border_bottom {
	border-bottom: 1px solid @gray-5;
}
.newsfeeder__card__border_left {
	border-left: 1px solid @gray-5;
}

.newsFeeder__card__feed {
	background-color: @gray-1;
	.newsfeeder__card__border_top();
	.newsfeeder__card__border_left();
	.newsfeeder__card__border_right();
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
}

.newsFeeder__card__top__round__borders {
	.newsfeeder__card__border_top();
	border-top-left-radius: @newsfeeder__card__border__radius;
	border-top-right-radius: @newsfeeder__card__border__radius;
}

.newsFeeder__card__bottom__round__borders {
	.newsfeeder__card__border_top();
	.newsfeeder__card__border_bottom();
	border-bottom-left-radius: @newsfeeder__card__border__radius;
	border-bottom-right-radius: @newsfeeder__card__border__radius;
}

.newsFeeder__card__feed__form {
	.newsfeeder__card__border_bottom();
}

.newsFeeder__card__feed__comment {
	.ant-btn {
		position: absolute;
		right: -3px;
		top: 0px;
	}
}

@media only screen and (max-width: @small) {
	.newsFeeder__card__feed__form {
		.ant-upload-list-item-name {
			font-size: 1.5vw;
		}
	}
}
