.main__sectionHeader__card {
	width: 100%;
	background-color: @blue-6;
	background-size: 100px;
	background-repeat: no-repeat;
	background-position-x: right;
	background-position-y: center;
	color: @gray-1;
}

.main__sectionHeader__btn__border {
	border-color: @gray-1;
}
