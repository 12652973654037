.popover__notification__container {
	max-height: 500px;
	max-width: 420px;
	overflow-y: auto;

	.notification__delete__icon {
		visibility: hidden;
	}

	.notification__mark__as__seen {
		background: @red-6;
		border-radius: 20px;
		width: 14px;
		height: 14px;
		display: inline-table;
		vertical-align: 2px;
	}

	.notification__item:hover {
		background-color: @blue-1;
		border-radius: 6x;
		.notification__delete__icon {
			visibility: visible;
		}
	}
}

.popover__notification__container::-webkit-scrollbar-track {
	background-color: @gray-1;
}

.popover__notification__container::-webkit-scrollbar {
	width: 5px;
	background-color: @gray-1;
}

.popover__notification__container::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
}

.fullScreen_notification__container {
	.popover__notification__container {
		max-height: 100%;
		padding-bottom: 100px;
	}
	z-index: 999999;
	background-color: @gray-1;
	width: 100vw;
	position: fixed;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	height: 100vh;
	top: 0px;
	.title {
		border-bottom: thin solid @gray-5;
		height: 59px;
	}
}
