//== Text Size Adjust
.text-size-adjust(@percentage: 100%) {
	text-size-adjust: @percentage;
	-ms-text-size-adjust: @percentage;
	-webkit-text-size-adjust: @percentage;
}

//== Flexbox mixins
.flexbox() {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

//== Flex Direction
.flexbox-direction(@dir) {
	-ms-flex-direction: @dir;
	-moz-flex-direction: @dir;
	flex-direction: @dir;
	-webkit-flex-direction: @dir;
}

.component-skin(@class, @skin, @content) {
	& when (@akademia--default-skin=@skin) {
		.@{class} {
			@content();
		}
	}

	& when not (@akademia--default-skin=@skin) {
		.@{class}.@{class}--skin-@{skin} {
			@content();
		}
	}
}
