//1:== Mixin Function
@import "./functions/_mixins.less";

//2:== Normalize HTML pages
@import "./normalize/_normalize.less";

@import "./display.less";

.border__bottom {
	border-bottom: 1px solid @gray-4;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	select,
	textarea,
	input {
		font-size: 16px !important;
	}
}
