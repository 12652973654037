.loggedInMenu__user {
	cursor: pointer;
}

.logged_in_menu__active_role {
	width: 100%;
	background: @gray-2;
	border-radius: 6px;
	padding: 8px;
	.role__name {
		color: @blue-6;
		font-weight: 600;
	}
}

@media only screen and (max-width: @xx-large) {
}

@media only screen and (max-width: @x-large) {
}

@media only screen and (max-width: @large) {
}

@media only screen and (max-width: @medium) {
}

@media only screen and (max-width: @small) {
	.loggedInMenu__fullname {
		display: none;
	}
}

@media only screen and (max-width: @x-small) {
}

.static__profile_avatar {
	display: flex;
	justify-content: center;
	background-color: @blue-6;
	img {
		width: 20px;
	}
}

.upload__profile_avatar {
	background-color: @blue-6;
}
