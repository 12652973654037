.download_mobile_app__background {
	background-color: #e9eefc;
}

.download_mobile_app__title {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 5px;
}

.download_mobile_app__subtitle {
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 20px;
}

.download_mobile_app__akelius {
	width: 165px;
	font-size: 12px;
}

.download_mobile_app__image {
	cursor: pointer;
}

@media only screen and (max-width: 465px) {
	.download_mobile_app__flex {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.download_mobile_app__flex > * {
		margin: 5px 0;
		text-align: center;
	}
}
