.override-ant-butto {
	.ant-btn {
		position: static;
		font-family: Inter;
		font-style: normal;
		font-weight: normal;
	}
}

.primary-button {
	background-color: @primary-color;
	border-color: @primary-color;
	color: @gray-1;
	.override-ant-butto;
	.override-primary;
}

.primary-button:hover,
.primary-button:focus,
.primary-button:active,
.primary-button.active {
	text-decoration: none;
	background: @success-background;
}
.primary-button:hover,
.primary-button:focus {
	color: @gray-1;
	background-color: @success-background;
	border-color: @success-border;
}
// end primary button

// error button
.error-button {
	background-color: @error-color;
	border-color: @error-color;
	color: @gray-1;
	.override-ant-butto;
	.override-error;
}

.error-button:hover,
.error-button:focus,
.error-button:active,
.error-button.active {
	text-decoration: none;
	background: @error-background;
}
.error-button:hover,
.error-button:focus {
	color: @gray-1;
	background-color: @error-background;
	border-color: @error-border;
}
// end error button

// warning button
.warning-button {
	background-color: @warning-color;
	border-color: @warning-color;
	color: @gray-1;
	.override-ant-butto;
	.override-warning;
}

.warning-button:hover,
.warning-button:focus,
.warning-button:active,
.warning-button.active {
	text-decoration: none;
	background: @warning-background;
}
.warning-button:hover,
.warning-button:focus {
	color: @gray-1;
	background-color: @warning-background;
	border-color: @warning-border;
}
// end warning button

// info button
.info-button {
	background-color: @info-color;
	border-color: @info-color;
	color: @gray-1;
	.override-ant-butto;
	.override-info;
}

.info-button:hover,
.info-button:focus,
.info-button:active,
.info-button.active {
	text-decoration: none;
	background: @warning-background;
}
.info-button:hover,
.info-button:focus {
	color: @gray-1;
	background-color: @warning-background;
	border-color: @warning-border;
}

.override-primary {
	.ant-btn-default:hover,
	.ant-btn-default:focus {
		background-color: @success-background;
		border-color: @success-border;
		color: @gray-1;
	}
	.ant-btn:hover,
	.ant-btn:focus {
		position: static;
		font-family: Inter;
		font-style: normal;
		font-weight: normal;
		background-color: @success-background;
		border-color: @success-border;
		color: @gray-1;
	}
}

.override-error {
	.ant-btn:active,
	.ant-btn.active {
		background-color: @error-background;
		border-color: @error-border;
		color: @gray-1;
	}
	.ant-btn:hover,
	.ant-btn:focus {
		background-color: @error-background;
		border-color: @error-border;
		color: @gray-1;
	}
}

.override-warning {
	.ant-btn-default:hover,
	.ant-btn-default:focus {
		background-color: @warning-background;
		border-color: @warning-border;
		color: @gray-1;
	}
	.ant-btn:hover,
	.ant-btn:focus {
		background-color: @warning-background;
		border-color: @warning-border;
		color: @gray-1;
	}
}

.override-info {
	.ant-btn-default:hover,
	.ant-btn-default:focus {
		background-color: @info-background;
		border-color: @info-border;
		color: @gray-1;
	}
	.ant-btn:hover,
	.ant-btn:focus {
		background-color: @info-background;
		border-color: @info-border;
		color: @gray-1;
	}
}

.outlined_button {
	color: @green-6;
	border: 1px solid @green-6;
}

.full_width_button {
	align-items: center;
	justify-content: center;
	display: flex;
	width: 100%;
}
