.quizMaterial__scene {
	&.quizMaterial__trophy_icon {
		font-size: 35px;
	}

	&.quizMaterial__body_quiz {
		max-height: 70vh;
		overflow: auto;
	}
}

.quiz__answer_preview_image {
	.image_preview {
		max-width: 100%;
		border: 1px solid @gray-1;
	}
	.selected_image_preview {
		border: 1px solid @blue-6;
		box-sizing: border-box;
		box-shadow: 0px 0px 4px rgba(32, 99, 227, 0.5);
	}
}
