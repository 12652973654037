.formik-datepicker {
	.ant-calendar-picker-input {
		border: none !important;
		border-radius: 0 !important;
		background: none !important;
		font-size: @line-height-base;
		line-height: @h4-line-height;
		padding: 0 !important;
	}
	border-bottom: 1px solid @border-color-base;
}
