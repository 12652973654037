@import "./chooseRole.less";
@import "./chooseAsStudent.less";

.select__organization_layout {
	.select__organization__header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.dummy__div {
			width: 100px;
		}
	}
	.ant-layout-content {
		background-color: @gray-2;
		padding: 40px 0 40px 0;
	}
	@media only screen and (max-width: 575px) {
		.select__organization__header {
			.dummy__div {
				display: none;
			}
			.dashboard__button {
				display: none;
			}
		}
	}
	@media only screen and (max-width: 767px) {
		.ant-layout-content {
			padding-left: 16px;
			padding-right: 16px;
		}
	}
	@media only screen and (min-width: 992px) {
		.select__organization__header {
			.dummy__div {
				width: 300px;
			}
		}
	}
}
