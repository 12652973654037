.formik-field__transition {
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: linear;
	-moz-transition-timing-function: linear;
	-o-transition-timing-function: linear;
	transition-timing-function: linear;
}

.radio__box__wrapper {
	background: @gray-1;
	border: 1px solid @gray-5;
	box-sizing: border-box;
	border-radius: @border-radius-base;
	white-space: pre-wrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.radio__element {
	.formik-field__transition;
	width: 100%;
	padding: 8px 16px 8px 16px;

	.radio__box__wrapper;

	&.ant-radio-wrapper-checked {
		background: @blue-1;
		border: 1px solid @blue-6;
		color: @blue-6;
	}

	&:hover {
		background: @blue-1;
		border: 1px solid @blue-6;
		color: @blue-6;
	}

	&.ant-radio-wrapper-disabled {
		background: @gray-3;
		border: 1px solid @gray-5;
		color: @gray-6;
	}
}

.radio__box__element {
	.formik-field__transition;
	width: 100%;
	height: 100%;
	padding: 24px 0 16px 16px;

	.radio__box__wrapper;
	.ant-radio {
		position: absolute;
		top: 8px;
		right: 7.5px;
	}

	span.ant-radio + * {
		padding-right: 0;
		padding-left: 0;
	}

	&.ant-radio-wrapper-checked {
		background: @blue-1;
		border: 1px solid @blue-6;
		color: @blue-6;
	}

	&:hover {
		background: @blue-1;
		border: 1px solid @blue-6;
		color: @blue-6;
	}

	&.ant-radio-wrapper-disabled {
		background: @gray-3;
		border: 1px solid @gray-5;
		color: @gray-6;
	}
}

.radio-with-checkmark {
	/* When the checkbox is checked, add  background */
	.ant-radio input:checked ~ .ant-radio-inner {
		background-color: @primary-color;
	}

	.ant-radio-inner {
		background-color: @body-background;
	}
	/* Create the checkmark/indicator (hidden when not checked) */
	.ant-radio-inner:after {
		content: "";
		position: absolute;
		display: none;
		background-color: @primary-color !important;
	}

	/* Show the checkmark when checked */
	.ant-radio input:checked ~ .ant-radio-inner:after {
		display: block;
	}

	.ant-radio-inner::after {
		position: absolute;
		display: table;
		border: solid @gray-1;
		border-width: 0 3px 3px 0;
		left: 5px !important;
		top: 2px !important;
		width: 5px !important;
		height: 10px !important;
		transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
		transform: rotate(45deg) !important;
	}
}

.ant-radio-group {
	width: 100%;

	.ant-radio-wrapper {
		font-size: @h5-font-size;
		line-height: @h5-line-height;
	}
}
