.course__builder__sectionHeader__card {
	width: 1136px;

	color: @gray-1;
	height: 100%;
	a {
		color: @gray-1;
	}

	.ant-card-body {
		padding: 0px;
	}

	.course__builder__sectionHeader__card_row_1 {
		padding: 20px;
		background-color: @blue-6;
		border-radius: 6px 6px 0px 0px;
	}

	.course__builder__sectionHeader__card_row_2 {
		padding: 20px;
		background-color: @gray-1;
		color: @gray-8;
		border-radius: 0px 0px 6px 6px;
		box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	}

	.main__sectionHeader__btn {
		position: absolute;
		bottom: 6px;
		right: 0px;
		z-index: 1;
	}

	.main__sectionHeader__img {
		width: 342px;
		position: absolute;
		bottom: -19px;
	}
}
