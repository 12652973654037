.settings__my_profile_card {
	border-color: @gray-3;
}

.settings__my_profile_footer {
	display: flex;
	flex-direction: row-reverse;
	padding: 10px 24px 10px 24px;
}
.settings__my_profile_avatar {
	width: 112px;
	height: 112px;
	display: flex;
	align-items: center;
}

.settings__my_profile_static_avatar {
	width: 112px;
	height: 112px;
	background-color: @blue-6;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		height: 100%;
		width: auto;
	}
}

.settings__my_profile_upload_avatar {
	width: 112px;
	height: 112px;
	display: flex;
	align-items: center;
}

.user__settings_static_avatars_list {
	height: 456px;
	overflow-y: auto;
	overflow-x: hidden;
	.user__settings__avatar_preview {
		padding-left: 0;
		text-align: center;
		.user__settings__avatar_preview_image {
			display: inline-block;
			position: relative;
			width: 78px;
			height: 78px;
			overflow: hidden;
			border-radius: 50%;
			img {
				width: auto;
				height: 100%;
			}
		}
	}
}

.user__settings_static_avatars_list::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: @gray-1;
}

.user__settings_static_avatars_list::-webkit-scrollbar {
	width: 10px;
	background-color: @gray-1;
}

.user__settings_static_avatars_list::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: rgba(0, 0, 0, 0.5);
}
