.courseBuilder__lessonMaterial {
	height: 100%;

	.divider {
		height: 100%;
	}

	.ant-select .ant-select-selector .ant-select-selection-placeholder {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
		align-self: center;
	}
}
