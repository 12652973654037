.main__section_title {
	padding: 16px 32px 16px 32px;
	background-color: @gray-1;
	border-bottom: 1px solid @gray-5;
}

@media only screen and (max-width: @small) {
	.btn-full-width {
		width: 100%;
		margin: 5px 0;
	}
}
