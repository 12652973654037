.dashboard__statistics_card {
	width: 100%;
	background-color: @gray-1;
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	border-radius: 6px;
	padding: 16px;
	.card__icon {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: @blue-6;
		background-color: @gray-2;
		font-size: 20px;
	}
}
