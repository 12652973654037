.videoMaterial__scene {
	&.videoMaterial__react-player-wrapper {
		position: relative;
		padding-top: 40%;
		.videoMaterial__react-player {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
