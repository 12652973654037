.course__preview_logo {
	width: 100%;
	position: relative;
	padding-bottom: 56.25%;
	/* ratio 16/9 */

	.course__thumbnail_image {
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 6px;
	}

	.course__thumbnail_icon {
		background: linear-gradient(180deg, #062f96 0%, #2063e3 100%);
		border-radius: 6px;
		display: flex;
		position: absolute;
		width: 100%;
		height: 100%;
		font-size: 4rem;
		justify-content: center;
		color: @blue-1;
	}
}

.course-item__tag-choose {
	&.ant-radio-group {
		width: unset;
	}
}

.course__preview_organization_name {
	padding: 1px 8px 1px 8px;
	background-color: @gray-3;
	border-radius: 4px;
}

.course__bought_successfully_modal {
	width: 480px !important;
	.ant-modal-body {
		padding: 0;
		.cover__image_content {
			background-color: @blue-1;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 28px 0 28px 0;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}
		.trying__enrolling_paid_course_skeleton {
			padding: 12px;
		}
	}
}
