.dashboard__classSlider__card {
	overflow: hidden;
	border: none;
	box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
	.ant-card-body {
		padding: 24px 24px 12px 24px;

		.classroom__details {
			.organization__name {
				color: @gray-7;
				border-radius: 4px;
				background-color: @gray-3;
				padding: 2px 8px 2px 8px;
			}
		}
	}
	.row__show_some {
		flex-flow: nowrap !important;
	}

	.row__show_all {
		flex-flow: wrap !important;
	}
	.dashboard__classSlider_nav_icon {
		position: absolute;
		top: 50%;
		z-index: 1;
	}
	.dashboard__classSlider_nav_icon_right {
		right: 8px;
	}
	.dashboard__classSlider_nav_icon_left {
		left: 8px;
	}

	.dashboard__classSlider_item_card_image {
		width: 100%;
		height: auto;
		border-radius: 6px;
		background-color: @blue-6;
		height: 100%; //for safari
	}
}
