.sidebar__organization_info {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 0 8px 12px 8px;
	.organization__avatar {
		border: 1px solid @blue-3;
	}
	.organization__name_minimized {
		display: none;
	}
	.organization__role_title_content {
		background: @blue-1;
		border-radius: 6px;
		padding: 0px 8px;
	}
	.go__to_manage_button {
		width: 100%;
		justify-content: center;
		cursor: pointer;
		padding: 8px 0 8px 0;
	}
	.go__to_manage_button:hover {
		background: @blue-1;
		border-radius: 6px;
		i,
		span {
			color: @blue-6;
		}
	}
}

.ant-layout-sider-collapsed {
	.organization__name,
	.organization__role_title,
	.manage__label {
		display: none;
	}
	.organization__name_minimized {
		display: block;
		margin-top: -24px;
	}
	.go__to_manage_button:first-child {
		margin-right: 0;
	}
}
