@intro-message-default-width: 588px;
@intro-message-medium-width: 496px;

.account__layout {
	.account__header {
		.account__header__logo {
			height: 24px;
		}
	}
	@media only screen and (min-width: 992px) {
		.account__header {
			display: none;
		}
	}
	.layout__content {
		flex: 1;
		.intro__message_content {
			flex: 1;
		}
		.account__form__content__wrapper {
			display: flex;
			justify-content: center;
			background-color: @gray-2;
			.account__form__content {
				margin-top: 120px;
				margin-bottom: @space-80;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}
		@media only screen and (min-width: 320px) {
			.intro__message_content {
				display: none;
			}
			.account__form__content__wrapper {
				width: 100%;
				.account__form__content {
					margin-top: 64px;
					width: 288px;
				}
			}
		}
		@media only screen and (min-width: 576px) {
			.account__form__content__wrapper {
				.account__form__content {
					width: 496px;
				}
			}
		}
		@media only screen and (min-width: 768px) {
			.account__form__content__wrapper {
				.account__form__content {
					width: 639px;
				}
			}
		}
		@media only screen and (min-width: 992px) {
			.intro__message_content {
				display: initial;
				width: @intro-message-medium-width;
			}
			.account__form__content__wrapper {
				width: calc(100% - @intro-message-medium-width);
				.account__form__content {
					width: 368px;
				}
			}
		}
		@media only screen and (min-width: 1200px) {
			.intro__message_content {
				width: @intro-message-default-width;
			}
			.account__form__content__wrapper {
				width: calc(100% - @intro-message-default-width);
				.account__form__content {
					width: 550px;
				}
			}
		}
	}
}
