.admin__table {
	border: thin solid @gray-3;
	border-radius: 5px;
	background-color: white;
	.ant-table-thead {
		.ant-table-cell {
			background-color: @gray-3;
			font-size: 14px;
			line-height: 22px;
			color: @gray-9;
			font-weight: 600;
		}
	}
	.ant-pagination {
		padding-right: 10px;
	}
}
