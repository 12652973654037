.subject__thumbnail_icon_content {
	background: linear-gradient(180deg, #062f96 0%, #2063e3 100%);
	border-radius: 6px;
	display: flex;
	justify-content: center;
	padding-top: 28px;
	padding-bottom: 28px;
	cursor: pointer;
	i {
		color: @blue-1;
		font-size: 40px;
	}
}
