#PdfRender__Container {
	margin: auto;
	width: 100%;
	padding-bottom: 20px;
}

.PdfRender__PDFPage {
	height: 100%;
	width: auto;
}

.PdfRender__PDFPage > canvas {
	max-width: 100%;
	height: auto !important;
}

.PdfRender__controls {
	border-top: thin solid @gray-2;
	position: absolute;
	width: 100%;
	z-index: 100;
	margin-top: -32px;
	opacity: 0.9;
}

.PdfRender__iframe {
	width: 100%;
	height: 792px;
}

@media only screen and (min-height: 720px) {
	.PdfRender__iframe {
		height: 430px;
	}
}

@media only screen and (min-height: 900px) {
	.PdfRender__iframe {
		height: 750px;
	}
}

@media only screen and (min-height: 1080px) {
	.PdfRender__iframe {
		height: 792px;
	}
}
